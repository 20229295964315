import { Vector2, Vector3 } from 'three';

import type { CartesianPose, CartesianPosition, Plane } from '@sb/geometry';
import {
  cameraPoseFromWristPose,
  deprojectOntoPlane,
  getTooltipOrientationPerpendicularToPlane,
} from '@sb/geometry';
import type { CameraIntrinsics } from '@sb/integrations/camera/types';

import type CameraInterface from './CameraInterface';
import type { RoutineRunnerState } from './RoutineRunnerState';
import type { RunVisionMethodArgs, RunVisionMethodResult } from './types';
import type { Blob2D, VisionInterface } from './VisionInterface';

export class VisionMethodRunner {
  private camera: CameraInterface;

  private vision: VisionInterface;

  private getState: () => RoutineRunnerState;

  public constructor(args: {
    camera: CameraInterface;
    vision: VisionInterface;
    getState: () => RoutineRunnerState;
  }) {
    this.camera = args.camera;
    this.vision = args.vision;
    this.getState = args.getState;
  }

  private deproject2DBlobs(
    blobs: Blob2D[],
    wristPose: CartesianPose,
    planeVertices: [CartesianPosition, CartesianPosition, CartesianPosition],
    cameraIntrinsics: CameraIntrinsics,
    resultsLimit: number,
  ): Array<{ blob: Blob2D; pose: CartesianPose }> {
    const results: Array<{ blob: Blob2D; pose: CartesianPose }> = [];

    const plane: Plane = {
      origin: new Vector3(
        planeVertices[0].x,
        planeVertices[0].y,
        planeVertices[0].z,
      ),
      plusX: new Vector3(
        planeVertices[1].x,
        planeVertices[1].y,
        planeVertices[1].z,
      ),
      plusY: new Vector3(
        planeVertices[2].x,
        planeVertices[2].y,
        planeVertices[2].z,
      ),
    };

    for (const blob of blobs) {
      const position = deprojectOntoPlane({
        pixelCoordinates: new Vector2(blob.x, blob.y),
        plane,
        cameraPose: cameraPoseFromWristPose(wristPose),
        cameraIntrinsics,
      });

      const rotation = getTooltipOrientationPerpendicularToPlane(
        plane,
        blob.rotation,
      );

      if (position) {
        results.push({
          blob,
          pose: {
            ...position,
            w: rotation.w,
            i: rotation.x,
            j: rotation.y,
            k: rotation.z,
          },
        });

        if (results.length >= resultsLimit) {
          break;
        }
      }
    }

    return results;
  }

  public async run(args: RunVisionMethodArgs): Promise<RunVisionMethodResult> {
    switch (args.method) {
      case 'classify': {
        const image = await this.camera.getColorFrame(args.camera);

        const results = await this.vision.classify(
          args.classes,
          image,
          args.regionOfInterest,
        );

        return { method: 'classify', results };
      }

      case 'detect2DBlobs': {
        const image = await this.camera.getColorFrame(args.camera);

        const results = await this.vision.detect2DBlobs(
          image,
          args.regionOfInterest,
          args.params,
        );

        return { method: 'detect2DBlobs', results };
      }

      case 'detect2DShapes': {
        const image = await this.camera.getColorFrame(args.camera);

        const results = await this.vision.detect2DShapes(
          image,
          args.templateImage,
          args.regionOfInterest,
          args.params,
        );

        return { method: 'detect2DShapes', results };
      }

      case 'getChessboardCorners': {
        const image = await this.camera.getColorFrame(args.camera);

        const results = await this.vision.getChessboardCorners(
          image,
          args.rows,
          args.cols,
        );

        return { method: 'getChessboardCorners', results };
      }

      case 'getCameraChessboardTransform': {
        const image = await this.camera.getColorFrame(args.camera);
        const intrinsics = await this.camera.getIntrinsics();

        const results = await this.vision.getCameraChessboardTransform(
          image,
          args.rows,
          args.cols,
          args.squareSizeMM,
          intrinsics,
        );

        return { method: 'getCameraChessboardTransform', results };
      }

      case 'locate2DBlobs': {
        const image = await this.camera.getColorFrame(args.camera);
        const intrinsics = await this.camera.getIntrinsics();

        const blobs = await this.vision.detect2DBlobs(
          image,
          args.regionOfInterest,
          args.params,
        );

        const results = this.deproject2DBlobs(
          blobs,
          this.getState().kinematicState.wristPose,
          args.plane,
          intrinsics,
          args.resultsLimit,
        );

        return {
          method: 'locate2DBlobs',
          results,
        };
      }

      case 'locate2DShapes': {
        const image = await this.camera.getColorFrame(args.camera);
        const intrinsics = await this.camera.getIntrinsics();

        const shapes = await this.vision.detect2DShapes(
          image,
          args.templateImage,
          args.regionOfInterest,
          args.params,
        );

        const results = this.deproject2DBlobs(
          shapes,
          this.getState().kinematicState.wristPose,
          args.plane,
          intrinsics,
          args.resultsLimit,
        );

        return {
          method: 'locate2DShapes',
          results,
        };
      }

      case 'getIntrinsics': {
        const results = await this.camera.getIntrinsics();

        return { method: 'getIntrinsics', results };
      }

      default:
        // @ts-expect-error [TS2339] args should be `never`
        throw new Error(`Unrecognised vision method ${args?.method}`);
    }
  }
}
