import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { JERK_LIMIT_DEFAULT } from '@sb/motion-planning';
import type { RoutineRunnerState, SpeedProfile } from '@sb/routine-runner';
import { calculateSpeedProfileFromSafetyLimits } from '@sb/routine-runner';
import { getRobot, getRoutine, getSafetySettings } from '@sbrc/services';
import {
  LINK_TO_PREFLIGHT_PAGE,
  calculateSpeedProfile,
  convertRoutineToRoutineRunnerSchema,
  truncateStepsWithActionRequired,
} from '@sbrc/utils';

import { useEquipment } from './useEquipment';
import useIsRobotConnected from './useIsRobotConnected';
import useRobotFailureState from './useRobotFailureState';
import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import useToast from './useToast';

type RoutineRunning = RoutineRunnerState & { kind: 'RoutineRunning' };

interface UsePlayRobotArguments {
  /**
   * Before we start playing a routine, a user should go through the prelight
   * checks first. If this argument is omitted or set to `false`, then calling
   * `onPlay` will redirect the user to the preflight checks page instead.
   *
   * If you're sure that the robot has completed all preflight checks and it's
   * ready to run this routine, then you can set this argument to `true`.
   */
  hasCompletedPreflightChecks?: boolean;
  isVizbot?: boolean;
  robotID: string;
}

interface OnPlayArguments {
  speedProfile?: SpeedProfile;
  startConditions?: Pick<RoutineRunning, 'currentStepID' | 'variables'>;
  isPreflightTestRun?: boolean;
}

/**
 * This custom hooks implements the business logic for
 * playing/pausing a routine. You need to pass a
 * routine runner handle as an argument to avoid having
 * multiple connections open for the same robot when using
 * multiple hooks.
 */
export default function usePlayRobot({
  hasCompletedPreflightChecks,
  isVizbot,
  robotID,
}: UsePlayRobotArguments) {
  const { asPath, push } = useRouter();

  const { setToast } = useToast();

  const routineRunnerFailure = useRobotFailureState({ isVizbot });

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isConnected = useIsRobotConnected({ isVizbot });

  const robotEquipment = useEquipment();

  const onPause = useCallback(async () => {
    if (routineRunnerHandle.getState()?.kind !== 'RoutineRunning') {
      return;
    }

    setToast({ kind: 'progress', message: 'Pausing routine...' });

    try {
      await routineRunnerHandle.pauseRoutine();
      setToast({ kind: 'success', message: 'Routine paused!' });
    } catch (e: any) {
      setToast({ kind: 'error', message: e.message });
    }
  }, [routineRunnerHandle, setToast]);

  const onPlay = async (args?: OnPlayArguments) => {
    const routineRunnerState = routineRunnerHandle.getState();

    const isRoutineRunning = routineRunnerState?.kind === 'RoutineRunning';

    try {
      const robot = await getRobot(robotID);

      if (!robot) {
        throw new Error('Robot not found');
      }

      if (!isConnected) {
        throw new Error(`${robot.name} is not connected.`);
      }

      if (!robot.latestRoutineID) {
        throw new Error('No routine is loaded onto the robot.');
      }

      const shouldStartPlaying =
        hasCompletedPreflightChecks || isRoutineRunning || isVizbot;

      if (shouldStartPlaying) {
        setToast({ kind: 'progress', message: 'Starting routine...' });
      }

      const routine = await getRoutine(robot.latestRoutineID);

      if (routine === null) {
        throw new Error('Routine not found.');
      }

      const safetySettings = await getSafetySettings(robotID);

      const speedProfile = calculateSpeedProfile({
        safetySettings,
        additionalSpeedProfile: args?.speedProfile,
        speedRestrictionPercentage: robot.speedRestrictionPercentage,
      });

      const safetySpeedProfile = calculateSpeedProfileFromSafetyLimits(
        safetySettings,
        { speedMultiplier: 1 },
      );

      await routineRunnerHandle.setJerkLimit(
        safetySettings.jerkLimit ?? JERK_LIMIT_DEFAULT,
      );

      /**
       * Only load a new routine when the robot isn't running or the robot has completed preflight checks.
       * Otherwise, it will reset the state in the routine-runner.
       */
      if (!isRoutineRunning || args?.startConditions) {
        if (routine.isActionRequired) {
          routine.steps = truncateStepsWithActionRequired(
            routine.steps,
            routine.actionRequiredByStepID,
          );

          if (routine.steps.length === 0) {
            throw new Error('First routine step requires action');
          }
        }

        // Go to the preflight checks if the user is not in the preflight page.
        // We can skip the preflight checks when running simulations.

        if (!hasCompletedPreflightChecks && !isVizbot) {
          push(`${LINK_TO_PREFLIGHT_PAGE}?redirectTo=${asPath}`);

          return;
        }

        const schema = convertRoutineToRoutineRunnerSchema({
          routine,
          baseSpeedProfile: safetySpeedProfile,
          robotEquipment: robotEquipment ?? [],
        });

        const response = await routineRunnerHandle.loadRoutine(
          schema,
          args?.startConditions,
        );

        if (response.errors.length > 0) {
          const [error] = response.errors;
          const message = error.messages[0];

          if (typeof message === 'string') {
            throw new Error(message);
          }

          throw new Error(message.message);
        }
      }

      /**
       * `playRoutine()` takes a very long to resolve even though the
       * routine starts running right away. This was causing some issues
       * where this toast would be randomly displayed while the routine
       * was performing another action.
       *
       * By calling the toast before `playRoutine()` we ensure this issue
       * doesn't happen. Besides, in case `playRoutine()` fails, this toast
       * will be overwritten by the error one.
       */
      if (shouldStartPlaying) {
        setToast({ kind: 'success', message: 'Playing routine!' });
      }

      if (isRoutineRunning) {
        if (
          !routineRunnerState.isPreflightTestRun ||
          args?.isPreflightTestRun === false
        ) {
          // cannot change speed profile during preflight test run
          await routineRunnerHandle.changeRoutineSpeedProfile(speedProfile);
        }

        if (
          routineRunnerState.isPreflightTestRun &&
          args?.isPreflightTestRun === false
        ) {
          await routineRunnerHandle.skipPreflightTestRun();
        }

        if (routineRunnerState.isPaused) {
          // Resume the routine if it's already running and paused.
          await routineRunnerHandle.resumeRoutine();
        }

        // continue with currently playing routine
        return;
      }

      // Play the routine
      await routineRunnerHandle.playRoutine({
        firstArmMoveIsGuidedMode: args?.isPreflightTestRun && !isVizbot,
        isPreflightTestRun: args?.isPreflightTestRun,
        speedProfile,
        shouldPauseWhenCompleted: true,
      });
    } catch (e: any) {
      setToast({ kind: 'error', message: e.message });
    }
  };

  useEffect(() => {
    if (routineRunnerFailure?.failureReason) {
      setToast({
        kind: 'error',
        message: routineRunnerFailure?.failureReason,
      });
    }
  }, [routineRunnerFailure?.failureReason, setToast]);

  return { onPause, onPlay };
}
