import * as zod from 'zod';

import { NoGripperConfiguration } from '../../NoGripper/types/Configuration';
import { OnRobot2FG7GrippersConfiguration } from '../../OnRobot2FG7/types/Configuration';
import { OnRobot3FG15GrippersConfiguration } from '../../OnRobot3FG15/types/Configuration';
import { OR_DUAL_QUCK_CHANGER_SHORTNAME } from '../constants';

export const DualGripperCompatibleGrippers = zod.union([
  OnRobot2FG7GrippersConfiguration,
  OnRobot3FG15GrippersConfiguration,
  NoGripperConfiguration,
]);

export type DualGripperCompatibleGrippers = zod.infer<
  typeof DualGripperCompatibleGrippers
>;

export const OnRobotDualQuickChangerConfiguration = zod.object({
  kind: zod.literal('OnRobotDualQuickChanger'),
  name: zod.string().default(OR_DUAL_QUCK_CHANGER_SHORTNAME),
  grippers: zod.object({
    primary: DualGripperCompatibleGrippers,
    secondary: DualGripperCompatibleGrippers,
  }),
});

export type OnRobotDualQuickChangerConfiguration = zod.infer<
  typeof OnRobotDualQuickChangerConfiguration
>;
