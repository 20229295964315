// @ts-ignore (no typings)
import { isEqual, startCase } from 'lodash';

import type { CartesianPose } from '@sb/geometry';
import type { DeviceCommand } from '@sb/integrations/device';
import * as log from '@sb/log';
import type {
  ArmAndDeviceMotionPlan,
  ArmJointAccelerations,
  ArmJointPositions,
  ArmJointVelocities,
  ArmTarget,
  ForwardKinematicsRequest,
  FrameOfReference,
  JerkLimit,
  JointNumber,
  MotionKind,
  MotionPlan,
  MotionPlannerInterface,
  MotionPlanRequest,
} from '@sb/motion-planning';
import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_TOOLTIP_SPEED,
  ALL_TOOL_MOTIONS_INVALID,
  ArmAndDeviceMotionPlanner,
} from '@sb/motion-planning';
import { StandardBotsSDK } from '@sb/sb-robot-sdk';
import type { Bit, Six, Sixteen } from '@sb/utilities';
import { six, sixteen, toBit, wait } from '@sb/utilities';

import type CameraInterface from './CameraInterface';
import type { CommandResult } from './CommandResult';
import {
  HOLD_STEADY_POSITION_EPSILON,
  RECOVERY_SPEED_PROFILE,
  STILL_GOING_CHECK_INTERVAL_MS,
} from './constants';
import type EquipmentInterface from './EquipmentInterface';
import { FailureKind } from './FailureKind';
import type {
  AccelerationCollisionThresholds,
  JointSafetyLimits,
  RobotInterface,
} from './RobotInterface';
import { Routine } from './Routine';
import { RoutineContext } from './RoutineContext';
import type {
  LoadedRoutineState,
  RoutineRunnerState,
  RoutineRunning,
  RoutineRunningPauseDetails,
  RoutineRunningPauseKind,
} from './RoutineRunnerState';
import { humanReadablePauseKind } from './RoutineRunnerState';
import type { SpeedProfile } from './speed-profile';
import type { StepValidationMessage } from './Step';
import { LoopStep, WaitForConfirmationStep } from './Step';
import Step from './Step/Step';
import type {
  ActuateDeviceArgs,
  ButtonStateChangeEvent,
  IOLevel,
  IOStateChanged,
  JSRunner,
  LEDColorPattern,
  MotionResult,
  PayloadState,
  PlayRoutineArgs,
  RobotToExternalPort,
  SafeguardRule,
  StepFailure,
} from './types';
import { safeguardRuleIsTriggeredBy } from './types';
import type { VisionInterface } from './VisionInterface';
import { VisionMethodRunner } from './VisionMethodRunner';

/**
 * The interval to check for state updates from external sources.
 */
const STATE_UPDATE_CHECK_FREQUENCY_MS = 50;

/**
 * Max number of errors while recovering before raising an internal error
 */
const OUT_OF_JOINT_LIMIT_RECOVERY_MAX_RETRIES = 2;

/**
 * Time to wait between retries for out of joint limit recovery
 */
const OUT_OF_JOINT_LIMIT_RECOVERY_RETRY_WAIT_TIME = 500;

/**
 * Time to wait before invalidating guided mode
 */
const GUIDED_MODE_TIMEOUT_MS_DEFAULT = 300;

const ns = log.namespace('RoutineRunner');

/**
 * Main class that executes routines. This class can control a local simulator when in the browser,
 * or the robot when running on the robot. But it should not be set up to control the actual robot
 * from the browser. This is because we only want one routine runner to control the actual robot
 * so that we never have a situation where multiple browser-based routine runners are trying to
 * send commands to an actual robot at one time.
 */
export class RoutineRunner {
  public readonly isVizbot: boolean;

  private sdk: StandardBotsSDK;

  private routineContext: RoutineContext;

  private lastGuidedModeValidation = new Date();

  private startOfGuidedMode: Date | null = null;

  public guidedModeTimeoutMS = GUIDED_MODE_TIMEOUT_MS_DEFAULT;

  private steps?: Array<Step>;

  /**
   * This is used by methods that basically actively change the state of the routine runner to
   * the extent that another method should stop.
   *
   * For example, ad-hoc motions should always stop what they're doing if a `stop()` call comes in,
   * so we want to frequently check "should this method be interrupted?" after each time the
   * execution gets pre-empted.
   */
  private instructionID: number = 0;

  // Keep track of the currently running steps, including decorator steps (loop/if) and
  // steps within any 'RunInBackground' blocks
  private currentlyRunningSteps: Array<Step> = [];

  private set state(newState: Readonly<RoutineRunnerState>) {
    this.routineContext.setRoutineRunnerState(newState);
  }

  private get state(): Readonly<RoutineRunnerState> {
    return this.routineContext.getRoutineRunnerState();
  }

  private checkStateUpdatesInterval: ReturnType<typeof setInterval>;

  private cancelers: Array<() => void> = [];

  /**
   * Used internally during a routine play to determine whether we should be
   * actually playing the steps.
   *
   * Until we encounter the start step (first step by default), this should be false
   * and we should skip actually calling `.play()` on steps or updating state.
   *
   * Once we traverse through the steps as though we'd bee playing them, set this flag
   * to true so the remainder of the run can be run for real.
   */
  private hasEncounteredStartStep = false;

  private lastTorqueCollisionRecoveryAt = Number.MIN_SAFE_INTEGER;

  public constructor({
    motionPlanner,
    robot,
    camera,
    equipment,
    jsRunner,
    vision,
    isVizbot,
  }: {
    motionPlanner: MotionPlannerInterface;
    robot: RobotInterface;
    camera: CameraInterface;
    equipment: EquipmentInterface;
    jsRunner: JSRunner;
    vision: VisionInterface;
    isVizbot: boolean;
  }) {
    this.isVizbot = isVizbot;

    this.sdk = new StandardBotsSDK({
      url: `http://${process.env.ROBOT_HOSTNAME}:4242`,
      robotKind: isVizbot ? 'simulated' : 'live',
    });

    this.routineContext = new RoutineContext({
      robot,
      camera,
      motionPlanner,
      jsRunner,
      equipment,
      vision,
    });

    this.visionMethodRunner = new VisionMethodRunner({
      camera,
      vision,
      getState: () => this.routineContext.getRoutineRunnerState(),
    });

    // save cancelation function for destroy()
    const onVariablesChangeCancel = this.routineContext.onVariablesChange(
      (variables) => {
        if (this.state.kind === 'RoutineRunning') {
          this.state = {
            ...this.state,
            variables,
          };
        }
      },
    );

    this.cancelers.push(onVariablesChangeCancel);

    this.checkStateUpdatesInterval = setInterval(() => {
      this.update();
    }, STATE_UPDATE_CHECK_FREQUENCY_MS);

    const cancelControlSystemEventListener = this.robot.onControlSystemEvent(
      (event) => {
        switch (event.kind) {
          case 'controlSystemEvent': {
            // only errors and critical should cause a failure
            if (
              !['VIOLATION', 'ERROR', 'CRITICAL'].includes(
                event.controlSystemEvent.level,
              )
            ) {
              break;
            }

            this.pauseOrFail({
              failure: {
                kind: FailureKind.ControlSystemFailure,
                event,
              },
              failureReason: `Robot encountered a control system issue: ${event.controlSystemEvent.message}`,
            });

            break;
          }
          case 'eStopTriggered': {
            this.pauseOrFail({
              failure: {
                kind: FailureKind.EStopTriggered,
                source: event.eStopTriggered.source,
              },
              failureReason: `E-Stop was triggered`,
            });

            break;
          }
          case 'ioStateChanged': {
            try {
              this.handleIOStateChange(event);
            } catch (error) {
              log.error(
                ns`io.react.failure`,
                'Failure reacting to IO state change',
                error,
              );
            }

            break;
          }
          case 'collision': {
            const failureReason = `Joint ${event.collision.joint} encountered a collision. Disturbance detected: ${event.collision.disturbanceDetected} Nm. Threshold set to ${event.collision.disturbanceThreshold} Nm`;

            this.pauseOrFail({
              failure: {
                kind: FailureKind.TorqueCollisionFailure,
                event,
                isManualRecoveryRequired:
                  this.state.kinematicState.brakesEngaged ||
                  Date.now() - this.lastTorqueCollisionRecoveryAt < 5000,
              },
              failureReason,
            });

            break;
          }
          case 'outOfLimits': {
            this.fail(this.getJointLimitViolationFailure());
            break;
          }

          case 'botmanHeartbeatLost': {
            this.pauseOrFail({
              failure: {
                kind: FailureKind.BotmanHeartbeatLost,
              },
              failureReason: 'Internal communication failure',
            });

            break;
          }

          case 'botmanHeartbeatRegained': {
            this.removePauseKind('botmanHeartbeatLost');
            break;
          }

          case 'buttonStateChangeEvent': {
            this.handleButtonStateChange(event);
            break;
          }
          case 'powerBoardUnhealthy': {
            this.pauseOrFail({
              failure: {
                kind: FailureKind.PowerBoardCheckFailure,
              },
              failureReason: `EStop health check failed.`,
            });

            break;
          }
        }
      },
    );

    this.cancelers.push(cancelControlSystemEventListener);

    this.checkCollisions();

    // If any motion plan fails, check collisions.
    // This is sort of temporary. Once motion plan failures are better reported, we can
    // fail more specifically when motion plans fail.
    this.motionPlanner.onMotionPlan((_request, response) => {
      response.once('error', () => {
        this.checkCollisions();
      });
    });
  }

  /**
   * Remove listeners and release any resources
   */
  public destroy() {
    this.instructionID += 1;
    clearInterval(this.checkStateUpdatesInterval);
    this.cancelers.forEach((cancel) => cancel());
    this.routineContext.destroy();
    this.robot.destroy();
  }

  public static validateRoutine(routine: unknown): Routine {
    return Routine.parse(routine);
  }

  /**
   * Backdoor to access the routine context for the API.
   * Avoid using this if possible.
   */
  public getRoutineContextForAPI(): RoutineContext {
    return this.routineContext;
  }

  /**
   * This loads a routine to be run into the routine runner. When changes are made to a routine, it
   * should be reloaded.
   *
   * @category Command-loading
   * @param routine           the routine to be loaded.
   * @param startConditions   the conditions to start the routine with (including start step and
   *                          variable state)
   * @returns All validation errors with the ids of the steps that errored
   */
  public loadRoutine(
    routine: Routine,
    startConditions?: Pick<RoutineRunning, 'currentStepID' | 'variables'>,
  ): { errors: Array<StepValidationMessage> } {
    if (this.state.kind === 'RoutineRunning') {
      throw new Error('Cannot load a routine while the routine is running');
    }

    log.info(ns`loadRoutine.start`, 'Loading routine', routine.name);

    let validationResults: Array<StepValidationMessage> = [];

    const loadedSteps: Array<Step> = [];

    this.routineContext.resetOnLoadRoutine();

    if (startConditions?.variables) {
      Object.entries(startConditions.variables).forEach(
        ([stepID, variableState]) => {
          log.info(
            ns`loadRoutine.setVariable`,
            `Setting variable state for ${stepID} to ${variableState}`,
            {
              stepID,
              variableState,
            },
          );

          this.routineContext.setVariableState(stepID, variableState);
        },
      );
    }

    if (routine.equipment) {
      this.routineContext.equipment.resetEquipmentList(routine.equipment);
    }

    // go through and create each step based on the type - store a list of steps
    routine.steps.forEach((step) => {
      // constructStep recurses
      const value = Step.constructStep(step, this.routineContext);

      if ('errors' in value) {
        validationResults = validationResults.concat(value.errors);

        return;
      }

      loadedSteps.push(value.step);
    });

    this.steps = loadedSteps;

    if (validationResults.length > 0) {
      return { errors: validationResults };
    }

    const startStepID = startConditions?.currentStepID ?? routine.steps[0].id;

    {
      // traverse through steps to ensure start step is valid
      let steps = [...this.steps];
      let step: Step | undefined;

      // eslint-disable-next-line no-cond-assign
      while ((step = steps.pop())) {
        if (step.id === startStepID) {
          break;
        }

        if (step.substeps) {
          steps = [...steps, ...step.substeps];
        }
      }

      if (!step) {
        throw new Error(`Could not find starting step ID ${startStepID}`);
      }
    }

    this.routineContext.loadedRoutineState = {
      ...routine,
      hasRunGuided: false,
      startStepID,
    };

    this.update();

    return { errors: [] };
  }

  /**
   * @category Commands
   * @throws if the state is not Idle
   * @throws if the robot is braked
   */
  public async moveToJointSpacePoint(
    goal: ArmJointPositions,
    options: SpeedProfile,
  ): Promise<CommandResult> {
    await this.checkCollisions();

    if (this.state.kind !== 'Idle') {
      return 'NonIdle';
    }

    if (this.robot.isRobotBraked()) {
      return 'Braked';
    }

    this.validateGuidedMode();

    this.state = {
      kind: 'RunningAdHocCommand',
      command: {
        kind: 'MoveToJointSpacePoint',
        jointPositions: goal,
      },
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      // start outside of guided mode because the motion plan sometimes
      // uses a lot of executor time
      shouldCheckGuidedMode: false,
    };

    this.instructionID += 1;
    const { instructionID } = this;

    await this.untilStill(5000);

    if (instructionID !== this.instructionID) {
      return 'Interrupted';
    }

    log.info(ns`moveToJointSpacePoint`, 'Moving to joint space point', {
      goal,
      jointAngles: this.getJointAngles(),
    });

    const request = {
      startingJointPositions: this.getJointAngles(),
      targets: [{ jointAngles: goal, motionKind: 'joint' }] as [ArmTarget],
      ...this.routineContext.getMotionPlanOptions(options),
    };

    const motionPlanResponse = this.motionPlanner.planMotion(request);

    let motionPlan: MotionPlan;

    try {
      motionPlan = await motionPlanResponse.complete();
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return 'Interrupted';
      }

      this.fail({
        failure: { kind: FailureKind.PlanningFailure },
        failureReason: `Failed to plan motion.  Arm may be colliding with itself or another object.  Message: ${error.message}`,
        error,
      });

      return FailureKind.PlanningFailure;
    }

    if (this.instructionID !== instructionID) {
      // pre-empted by something like `stop` or `pause`
      return 'Interrupted';
    }

    this.state = {
      ...this.state,
      shouldCheckGuidedMode: true,
    };

    try {
      this.checkMotionResult(
        await this.robot.playMotionPlan({
          motionPlan,
          maxJointVelocities: request.maxVelocities,
          maxJointAccelerations: request.maxAccelerations,
          maxTooltipVelocity: request.maxTooltipVelocity,
          checkTorqueLimitsRealtime: true,
        }),
      );
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return 'Interrupted';
      }

      this.fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Failed to execute motion',
        error,
      });

      return FailureKind.ExecutionFailure;
    }

    this.state = {
      kind: 'Idle',
      cannotStartReasons: this.cannotStartReasons(),
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      validToolDirections: ALL_TOOL_MOTIONS_INVALID,
    };

    this.update();

    return 'Success';
  }

  /**
   * @category Commands
   * @throws if the state is not Idle
   * @throws if the robot is braked
   */
  public async moveToCartesianSpacePose(
    goal: CartesianPose,
    motionKind: MotionKind,
    options: SpeedProfile,
  ): Promise<CommandResult> {
    await this.checkCollisions();

    if (this.state.kind !== 'Idle') {
      return 'NonIdle';
    }

    if (this.robot.isRobotBraked()) {
      return 'Braked';
    }

    this.validateGuidedMode();

    this.state = {
      kind: 'RunningAdHocCommand',
      command: {
        kind: 'MoveToCartesianSpacePose',
        pose: goal,
        motionKind,
      },
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      // start outside of guided mode because the motion plan sometimes
      // uses a lot of executor time
      shouldCheckGuidedMode: false,
    };

    this.instructionID += 1;
    const { instructionID } = this;

    await this.untilStill(5000);

    if (instructionID !== this.instructionID) {
      return 'Interrupted';
    }

    let motionPlanResult: ArmAndDeviceMotionPlan<DeviceCommand>;

    const request: MotionPlanRequest = {
      startingJointPositions: this.getJointAngles(),
      targets: [{ pose: goal, motionKind }] as [ArmTarget],
      ...this.routineContext.getMotionPlanOptions(options),
    };

    try {
      motionPlanResult = await new ArmAndDeviceMotionPlanner({
        motionPlanner: this.motionPlanner,
        request,
        deviceKinematics: this.routineContext.equipment.getDeviceKinematics(),
        tcpOffsetOption: this.state.kinematicState.tcpOffsetOption,
      }).plan();
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return 'Interrupted';
      }

      this.fail({
        failure: { kind: FailureKind.PlanningFailure },
        failureReason: `Failed to plan motion: ${error.message}`,
        error,
      });

      return FailureKind.PlanningFailure;
    }

    if (this.instructionID !== instructionID) {
      // pre-empted by something like `stop` or `pause`
      return 'Interrupted';
    }

    this.state = {
      ...this.state,
      shouldCheckGuidedMode: true,
    };

    try {
      for (const deviceCommand of motionPlanResult.deviceCommands) {
        await this.routineContext.actuateDevice(deviceCommand);

        if (this.instructionID !== instructionID) {
          // pre-empted by something like `stop` or `pause`
          return 'Interrupted';
        }
      }

      this.checkMotionResult(
        await this.robot.playMotionPlan({
          motionPlan: motionPlanResult.motionPlan,
          maxJointVelocities: request.maxVelocities,
          maxJointAccelerations: request.maxAccelerations,
          maxTooltipVelocity: request.maxTooltipVelocity,
          checkTorqueLimitsRealtime: true,
        }),
      );
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return 'Interrupted';
      }

      this.fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Failed to execute motion',
        error,
      });

      return FailureKind.ExecutionFailure;
    }

    this.state = {
      kind: 'Idle',
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      cannotStartReasons: this.cannotStartReasons(),
      validToolDirections: ALL_TOOL_MOTIONS_INVALID,
    };

    this.update();

    return 'Success';
  }

  /**
   * @category Commands
   * @throws if the state is not Idle
   * @throws if the robot is braked
   */
  public async moveJointRelative(
    jointNumber: JointNumber,
    options: SpeedProfile,
  ): Promise<void> {
    if (this.state.failureState?.recoveryType === 'ManualRecoveryMode') {
      if (this.state.kinematicState.jointBrakesEngaged[jointNumber]) {
        throw new Error(`Cannot move joint ${jointNumber} when it is braked`);
      }

      // `robot.manualRecoveryMode` will be reset when the state eventually returns to Idle
      await this.robot.setManualRecoveryMode(true);
    } else if (this.state.kind === 'Idle') {
      await this.checkCollisions();

      if (this.robot.isRobotBraked()) {
        throw new Error('Cannot move robot while robot is braked');
      }
    } else {
      throw new Error(`Cannot move robot while in state ${this.state.kind}`);
    }

    this.instructionID += 1;
    const { instructionID } = this;
    await this.untilStill(5000);

    if (instructionID !== this.instructionID) {
      return;
    }

    const maxJointVelocity = options.maxJointSpeeds[jointNumber];

    this.validateGuidedMode();

    this.state = {
      kind: 'RunningAdHocCommand',
      command: {
        kind: 'MoveJointRelative',
        jointNumber,
        maxJointVelocity,
      },
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      // start outside of guided mode because the motion plan sometimes
      // uses a lot of executor time
      shouldCheckGuidedMode: false,
    };

    const lineStartPosition = this.getJointAngles();

    const maxVelocities: ArmJointVelocities = six(0);
    const offset = six(0);
    // set offset to positive or negative for the correct joint
    offset[jointNumber] = maxJointVelocity > 0 ? 1 : -1;

    maxVelocities[jointNumber] = Math.abs(maxJointVelocity);

    let motionPlan: MotionPlan;

    const request = {
      startingJointPositions: this.getJointAngles(),
      lineStartPosition,
      offset,
      ...this.routineContext.getMotionPlanOptions(options),
    };

    try {
      const motionPlanResponse =
        this.motionPlanner.planRelativeJointMotion(request);

      motionPlan = await motionPlanResponse.complete();
    } catch (error) {
      this.restoreFailureStateKind();

      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return;
      }

      this.fail({
        failure: { kind: FailureKind.PlanningFailure },
        failureReason: `Failed to plan joint motion: ${error.message}`,
        error,
      });

      return;
    }

    try {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return;
      }

      this.state = {
        ...this.state,
        shouldCheckGuidedMode: true,
      };

      this.checkMotionResult(
        await this.robot.playMotionPlan({
          motionPlan,
          maxJointVelocities: request.maxVelocities,
          maxJointAccelerations: request.maxAccelerations,
          maxTooltipVelocity: request.maxTooltipVelocity,
          checkTorqueLimitsRealtime: true,
        }),
      );
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return;
      }

      this.fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Failed to execute joint motion',
        error,
      });
    } finally {
      this.restoreFailureStateKind();

      await this.untilStill(5000);
    }
  }

  /**
   * @category Commands
   * @throws if the state is not Idle
   * @throws if the robot is braked
   */
  public async moveToolRelative(
    frame: FrameOfReference,
    offset: CartesianPose,
    options: SpeedProfile,
  ) {
    await this.checkCollisions();

    if (this.state.kind !== 'Idle') {
      throw new Error(`Cannot move robot while in state ${this.state.kind}`);
    }

    if (this.robot.isRobotBraked()) {
      throw new Error('Cannot move robot while robot is braked');
    }

    this.instructionID += 1;
    const { instructionID } = this;
    await this.untilStill(5000);

    if (instructionID !== this.instructionID) {
      return;
    }

    this.validateGuidedMode();

    this.state = {
      kind: 'RunningAdHocCommand',
      command: {
        kind: 'MoveToolRelative',
        frame,
        offset,
        maxTooltipVelocity: options.maxTooltipSpeed,
      },
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      // start outside of guided mode because the motion plan sometimes
      // uses a lot of executor time
      shouldCheckGuidedMode: false,
    };

    const lineStartPosition = this.getJointAngles();

    let motionPlan: MotionPlan;

    const request = {
      startingJointPositions: this.getJointAngles(),
      lineStartPosition,
      frame,
      offset,
      ...this.routineContext.getMotionPlanOptions(options),
    };

    try {
      const motionPlanResponse =
        this.motionPlanner.planRelativeCartesianMotion(request);

      motionPlan = await motionPlanResponse.complete();
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return;
      }

      this.fail({
        failure: { kind: FailureKind.PlanningFailure },
        failureReason: `Failed to plan tooltip motion: ${error.message}`,
        error,
      });

      return;
    }

    try {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return;
      }

      this.state = {
        ...this.state,
        shouldCheckGuidedMode: true,
      };

      this.checkMotionResult(
        await this.robot.playMotionPlan({
          motionPlan,
          maxJointVelocities: request.maxVelocities,
          maxJointAccelerations: request.maxAccelerations,
          maxTooltipVelocity: request.maxTooltipVelocity,
          checkTorqueLimitsRealtime: true,
        }),
      );
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return;
      }

      this.fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: `Failed to enact tooltip motion: ${error.message}`,
        error,
      });
    }
  }

  /**
   * @category Commands
   *
   * Actuate a device.
   *
   * @param gripperCommand   The command to give the device.
   * @param payload         The payload to expect after the command completes.
   *
   * @resolves When the device command is complete.
   * @throws   If the state is not Idle.
   */
  public async actuateDevice({
    command: gripperCommand,
    payload,
    retryTimeoutMS,
  }: ActuateDeviceArgs): Promise<CommandResult> {
    if (this.state.kind !== 'Idle') {
      return 'NonIdle';
    }

    this.instructionID += 1;
    const { instructionID } = this;

    this.validateGuidedMode();

    this.state = {
      kind: 'RunningAdHocCommand',
      command: {
        kind: 'ActuateGripper',
        gripperCommand,
      },
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      shouldCheckGuidedMode: true,
    };

    try {
      await this.routineContext.actuateDevice(gripperCommand, {
        timeoutMS: retryTimeoutMS,
      });

      if (payload) {
        this.routineContext.setPayload(payload);
      }
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return 'Interrupted';
      }

      const failureReason = `Failed to actuate gripper: ${error.message}`;

      this.fail({
        failure: {
          kind: FailureKind.GripperFailure,
          message: failureReason,
        },
        failureReason,
        error,
      });

      return FailureKind.GripperFailure;
    }

    return 'Success';
  }

  /**
   * @category Commands
   *
   * Command the IO board's outputs to set a port to high or low.
   */
  public setOutputIO(
    changes: Array<{ label: RobotToExternalPort; level: IOLevel }>,
  ): Promise<void> {
    return this.robot.setOutputIO(changes);
  }

  /**
   * @category Commands
   *
   * Recover from a state of `Failure`. Action depends on the type of failure (`failureKind`)
   * but the interface is always that of an Ad-Hoc motion.
   *
   * - PlanningFailure
   *  - Clears the error; `recover()` indicates that the frontend/user has acknowledged the
   *    planning failure.
   * - ExecutionFailure
   *  - Clears the error; `recover()` indicates that the frontend/user has acknowledged the
   *    execution failure.
   * - OutOfJointLimitsFailure
   *  - Command/ad-hoc motion to recover from out of joint limits
   * - CollisionFailure & MotionPlannerFailureStartPositionCollision
   *  - Command/ad-hoc motion to recover from a collision
   * - MotionPlannerFailure
   *  - Clears the error; `recover()` indicates that the frontend/user has acknowledged the
   *    planning failure.
   * - InvalidRoutineLoadedFailure
   *  - Clears the error; `recover()` indicates that the frontend/user has acknowledged the
   *    planning failure.
   *  - Unloads the currently loaded routine
   * - InternalFailure
   *  - Throws an error (internal errors are unrecoverable)
   *
   * @param initializedByWristButton  set true if recovery attempted with wrist button
   *
   * @throws if the robot is not in a state of Failure
   * @throws if the state is not Failure
   * @throws if the robot is braked
   */
  public async recover(
    initializedByWristButton = false,
  ): Promise<CommandResult> {
    if (this.state.kind !== 'Failure') {
      throw new Error(
        `Tried to recover when in a(n) "${this.state.kind}" state`,
      );
    }

    if (
      initializedByWristButton &&
      !this.state.failureState.isRecoverableWithWristButton
    ) {
      throw new Error(
        `Wrist button recovery unavailable when in a(n) "${this.state.failureState.failure}" state`,
      );
    }

    const failureKind = this.state.failureState.failure.kind;

    let success = false;
    let recoverByMoving = false;

    if (this.state.failureState.recoveryType === 'ManualRecoveryMode') {
      await this.robot.setManualRecoveryMode(false);
    }

    switch (failureKind) {
      case FailureKind.OutOfJointLimitsFailure: {
        recoverByMoving = true;

        success = await this.recoverIntoJointLimits(RECOVERY_SPEED_PROFILE);

        break;
      }

      case FailureKind.MotionPlannerFailureStartPositionCollision:
      case FailureKind.CollisionFailure: {
        recoverByMoving = true;

        success = await this.recoverFromCollision(
          RECOVERY_SPEED_PROFILE,
          initializedByWristButton,
        );

        break;
      }

      case FailureKind.TorqueCollisionFailure: {
        await this.recoverFromCollision(
          RECOVERY_SPEED_PROFILE,
          initializedByWristButton,
        );

        // if the next torque collision is within 5 seconds to go into manual recovery mode
        this.lastTorqueCollisionRecoveryAt = Date.now();
        // always mark successful so we pick up the next torque collision failure
        success = true;
        break;
      }

      case FailureKind.InvalidRoutineLoadedFailure: {
        delete this.steps;
        this.routineContext.resetOnLoadRoutine();
        // remove routine from state
        this.routineContext.loadedRoutineState = undefined;
        success = true;
        break;
      }

      case FailureKind.InternalFailure: {
        throw new Error(
          'Tried to recover when an internal, unrecoverable error has occurred. These are errors that may require specific Standard Bots employee intervention.',
        );
      }

      case FailureKind.EStopTriggered: {
        if (this.robot.getSafeguardState() === 'eStop') {
          throw new Error('E-Stop must be reset to recover');
        }

        log.info(ns`estop.reset`, 'Resetting EStopTriggered');

        success = true;

        break;
      }

      default: {
        log.info(
          ns`reset.unknown`,
          `Resetting ${this.state.failureState.failure.kind}`,
          { failureState: this.state.failureState },
        );

        success = true;
        break;
      }
    }

    if (success) {
      if (recoverByMoving) {
        const isColliding = await this.checkCollisions();
        const isOutOfJointLimits = this.isOutOfJointLimits();

        if (isColliding) {
          return FailureKind.CollisionFailure;
        }

        if (isOutOfJointLimits) {
          return FailureKind.OutOfJointLimitsFailure;
        }

        log.info(ns`jointAngles.recovered`, 'Recovered to joint angles', {
          jointAngles: this.robot.getJointAngles(),
        });
      }

      this.state = {
        kind: 'Idle',
        kinematicState: this.state.kinematicState,
        configuration: this.state.configuration,
        failureState: null,
        cannotStartReasons: this.cannotStartReasons(),
        validToolDirections: ALL_TOOL_MOTIONS_INVALID,
      };
    }

    return 'Success';
  }

  private checkMotionResult(result: MotionResult) {
    switch (result.kind) {
      case 'torqueLimitExceeded':
        this.fail({
          failure: { kind: FailureKind.ExecutionFailure },
          failureReason: `Torque limited exceeded: ${result.torqueLimitExceeded.message}`,
        });

        throw new Error(
          `Torque limited exceeded: ${result.torqueLimitExceeded.message}`,
        );
    }
  }

  private async initializeRecoveringState(
    initializedByWristButton: boolean = false,
  ): Promise<boolean> {
    const { instructionID } = this;

    // unbrake before setting `Recovering` state kind,
    // otherwise a 'Robot braked in the middle of recovering' failure will be triggered
    try {
      if (this.robot.isRobotBraked()) {
        await this.robot.unbrake();
      }
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return false;
      }

      log.error(
        ns`arm.disengage.failure`,
        'Failed to disengage arm brakes',
        error,
      );

      throw new Error('Recovery failed: could not unbrake');
    }

    // the main thing we're checking is that it's not currently moving;
    // technically Idle would probably be fine, but it shouldn't be in state idle
    // if we're about to recover
    if (this.state.kind !== 'Failure') {
      throw new Error('Recovery failed: the robot is not in Failure state');
    }

    this.state = {
      kind: 'Recovering',
      kinematicState: this.state.kinematicState,
      configuration: this.state.configuration,
      failureState: this.state.failureState,
      // start out not checking guided mode because the motion plan sometimes
      // uses a lot of executor time
      shouldCheckGuidedMode: false,
      isRecoveringWithWristButton: initializedByWristButton,
    };

    await this.untilStill(5000);

    return instructionID === this.instructionID;
  }

  /**
   * Recover from being out of joint limits (similar to an ad-hoc motion)
   * Resolves to true if successful and false if not
   */
  private async recoverIntoJointLimits(
    options: SpeedProfile,
    retryCount: number = 0,
  ): Promise<boolean> {
    this.instructionID += 1;
    const { instructionID } = this;

    if (retryCount === 0 && !(await this.initializeRecoveringState())) {
      return false;
    }

    const startingJointPositions = this.getJointAngles();
    this.validateGuidedMode();

    log.info(ns`recoverIntoJointLimits.start`, 'Recovering from', {
      startingJointPositions,
    });

    let motionPlan: MotionPlan;

    const request = {
      startingJointPositions,
      ...this.routineContext.getMotionPlanOptions(options),
    };

    try {
      const response = this.motionPlanner.planRecoveryMotion(request);

      motionPlan = await response.complete();
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return false;
      }

      this.fail({
        failure: { kind: FailureKind.PlanningFailure },
        failureReason: `Failed to plan recovery motion: ${error.message}`,
        error,
      });

      return false;
    }

    if (
      this.state.kind !== 'Recovering' ||
      this.instructionID !== instructionID
    ) {
      return false;
    }

    this.state = {
      ...this.state,
      shouldCheckGuidedMode: true,
    };

    try {
      await this.robot.playMotionPlan({
        motionPlan,
        maxJointVelocities: request.maxVelocities,
        maxJointAccelerations: request.maxAccelerations,
        maxTooltipVelocity: request.maxTooltipVelocity,
      });
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return false;
      }

      this.fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Failed to execute recovery motion',
        error,
      });

      return false;
    }

    if (this.isOutOfJointLimits()) {
      if (retryCount <= OUT_OF_JOINT_LIMIT_RECOVERY_MAX_RETRIES) {
        log.warn(
          ns`joints.outOfLimits`,
          'Joints are out of limits, retrying recovery',
          {
            retryCount,
          },
        );

        await wait(
          OUT_OF_JOINT_LIMIT_RECOVERY_RETRY_WAIT_TIME * 2 ** retryCount,
        );

        return this.recoverIntoJointLimits(options, retryCount + 1);
      }

      log.error(
        ns`joints.outOfLimits.failure`,
        'joints are still out of limits after recovery, need manual intervention',
        { jointAngles: this.getJointAngles() },
      );

      this.fail(this.getJointLimitViolationFailure(false));

      return false;
    }

    return true;
  }

  /**
   * Recover from a collision (similar to an ad-hoc motion)
   * Resolves to true if successful and false if not
   */
  private async recoverFromCollision(
    options: SpeedProfile,
    initializedByWristButton = false,
  ): Promise<boolean> {
    this.instructionID += 1;
    const { instructionID } = this;

    if (!(await this.initializeRecoveringState(initializedByWristButton))) {
      return false;
    }

    const startingJointPositions = this.getJointAngles();

    let motionPlan: MotionPlan;

    const request = {
      startingJointPositions,
      ...this.routineContext.getMotionPlanOptions(options),
    };

    try {
      const response = this.motionPlanner.planRecoveryMotion(request);

      motionPlan = await response.complete();
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return false;
      }

      this.fail({
        failure: { kind: FailureKind.PlanningFailure },
        failureReason: `Failed to plan collision recovery motion: ${error.message}`,
        error,
      });

      return false;
    }

    if (
      this.state.kind !== 'Recovering' ||
      this.instructionID !== instructionID
    ) {
      return false;
    }

    this.state = {
      ...this.state,
      shouldCheckGuidedMode: !this.state.isRecoveringWithWristButton,
    };

    try {
      await this.robot.playMotionPlan({
        motionPlan,
        maxJointVelocities: request.maxVelocities,
        maxJointAccelerations: request.maxAccelerations,
        maxTooltipVelocity: request.maxTooltipVelocity,
        checkTorqueLimitsRealtime: true,
      });
    } catch (error) {
      if (this.instructionID !== instructionID) {
        // pre-empted by something like `stop` or `pause`
        return false;
      }

      this.fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Failed to execute collision recovery motion',
        error,
      });

      return false;
    }

    return true;
  }

  /**
   * Short-hand to quickly access the robot
   */
  private get robot(): RobotInterface {
    return this.routineContext.robot;
  }

  /**
   * Short-hand to quickly access the motion planner
   */
  private get motionPlanner(): MotionPlannerInterface {
    return this.routineContext.motionPlanner;
  }

  private lastStateUpdateTime = new Date();

  private get currentStepID(): string | undefined {
    if (this.state.kind === 'RoutineRunning') {
      return this.state.currentStepID;
    }

    return undefined;
  }

  private getJointLimitViolationFailure(
    recoverable: boolean = true,
  ): StepFailure {
    const limitViolations = this.jointLimitViolations().map(
      ({ error }) => error,
    ) as Six<number | null>;

    const jointAngles = this.getJointAngles();

    return {
      failure: {
        kind: FailureKind.OutOfJointLimitsFailure,
        jointAngles,
        limitViolations,
        recoverable,
      },
      stepID: this.currentStepID,
      failureReason: (() => {
        let reason = 'The following joints are out of joint limits: ';

        const violationMessages = limitViolations
          .map((violation, index) =>
            typeof violation === 'number'
              ? `Joint ${index} at position ${jointAngles[index]}`
              : null,
          )
          .filter((jointError) => jointError !== null) as Array<string>;

        reason += violationMessages.join(', ');

        return reason;
      })(),
    };
  }

  /**
   * This function checks if the state needs to be updated due to external conditions changing.
   * The intention is to call it whenever a state change might be viable. In practice, it runs
   * on an interval to make sure we don't miss realtime connection validation updates.
   */
  private async update() {
    try {
      const now = new Date();
      this.routineContext.updateKinematicState();

      // Go into a failure state if the joints are out of limits.
      if (
        this.isOutOfJointLimits() &&
        // don't report if we're already marked as Failure or if we're trying to recover
        this.state.kind !== 'Failure' &&
        this.state.kind !== 'Recovering'
      ) {
        const violations = this.jointLimitViolations();

        log.error(ns`outOfLimits.detected`, 'Out of joint limits detected', {
          jointAngles: this.getJointAngles(),
          violations,
        });

        await this.fail(this.getJointLimitViolationFailure());
      }

      // If guidedModeInvalidation is populated (non-null), we should `stop` if we're
      // in Ad-Hoc mode.
      //
      // Otherwise, we just invalidate startOfGuidedMode and skip logging or stopping.
      let guidedModeInvalidation: string | null = null;

      if (!this.isGuidedModeValid()) {
        const diff = now.getTime() - this.lastGuidedModeValidation.getTime();
        const start = this.startOfGuidedMode ?? new Date();
        const wholeDuration = now.getTime() - start.getTime();
        this.startOfGuidedMode = null;

        guidedModeInvalidation = `guided mode has not been validated (${diff}ms = now ${now} - last validation ${this.lastGuidedModeValidation.getTime()}). Guided mode lasted ${wholeDuration}ms`;
      }

      switch (this.state.kind) {
        case 'Idle': {
          if (this.robot.getWristButtonStates()[0]) {
            this.state = {
              ...this.state,
              // double tapped button sets second bool to true
              kind: this.robot.getWristButtonStates()[1]
                ? 'AntigravitySlow'
                : 'Antigravity',
            };
          }

          break;
        }

        case 'RunningAdHocCommand': {
          if (this.state.shouldCheckGuidedMode && guidedModeInvalidation) {
            this.stop(guidedModeInvalidation);
            break;
          }

          if (this.robot.isRobotBraked()) {
            this.fail({
              failure: { kind: FailureKind.ExecutionFailure },
              failureReason: `Robot braked in the middle of executing the "${startCase(
                this.state.command.kind,
              )}" command`,
            });

            break;
          }

          if (this.robot.getWristButtonStates()[0]) {
            this.fail({
              failure: { kind: FailureKind.ExecutionFailure },
              failureReason: `Antigravity button pressed during "${startCase(
                this.state.command.kind,
              )}" command`,
            });

            break;
          }

          break;
        }

        case 'Recovering': {
          if (this.state.shouldCheckGuidedMode && guidedModeInvalidation) {
            this.stop(guidedModeInvalidation);
            break;
          }

          if (
            !this.robot.getWristButtonStates()[0] &&
            this.state.isRecoveringWithWristButton
          ) {
            this.stop('Wrist button released during recovery');
            break;
          }

          if (
            this.state.failureState.recoveryType !== 'ManualRecoveryMode' &&
            this.robot.isRobotBraked()
          ) {
            this.fail({
              failure: { kind: FailureKind.ExecutionFailure },
              failureReason: 'Robot braked in the middle of recovering',
            });

            break;
          }

          if (
            this.robot.getWristButtonStates()[0] &&
            !this.state.isRecoveringWithWristButton
          ) {
            this.fail({
              failure: { kind: FailureKind.ExecutionFailure },
              failureReason: 'Antigravity button pressed during recovery',
            });

            break;
          }

          break;
        }

        case 'RoutineRunning': {
          if (this.state.shouldCheckGuidedMode) {
            if (guidedModeInvalidation) {
              if (!this.state.isPaused) {
                log.info(
                  ns`guidedModeInvalidation.detected`,
                  'Pausing routine until guided mode continues:',
                  guidedModeInvalidation,
                );

                this.pauseRoutine({ kind: 'guidedModeInvalidation' });
              }

              break;
            }
          }

          if (this.robot.getWristButtonStates()[0]) {
            this.fail({
              failure: { kind: FailureKind.ExecutionFailure },
              failureReason:
                'Antigravity button was pressed in the middle of a routine',
              stepID: this.currentStepID,
            });

            break;
          }

          if (!this.state.isPaused) {
            const elapsedMilliseconds =
              now.getTime() - this.lastStateUpdateTime.getTime();

            this.state = {
              ...this.state,
              runTimeSeconds:
                this.state.runTimeSeconds + elapsedMilliseconds / 1000,
            };
          }

          const mainLoop = this.routineContext.loadedRoutineState?.mainLoop;

          if (mainLoop) {
            let variables;

            try {
              variables = this.routineContext.getVariableState(mainLoop);
            } catch (e) {
              log.warn(
                ns`mainLoop.variables.notFound`,
                `Tried to access main loop ${mainLoop} variables but the step does not exist`,
                { mainLoop, routine: this.routineContext.loadedRoutineState },
              );

              break;
            }

            try {
              variables = LoopStep.Variables.parse(variables);
            } catch (e) {
              log.warn(
                ns`mainLoop.variables.invalid`,
                `Tried to access main loop ${mainLoop} variables but the step is not a loop step`,
                { mainLoop, routine: this.routineContext.loadedRoutineState },
              );

              return;
            }

            this.state = {
              ...this.state,
              cycleCount: variables.currentIteration,
            };

            if (typeof variables.expectedTotalIterations === 'number') {
              this.state = {
                ...this.state,
                totalExpectedCycles: variables.expectedTotalIterations,
              };

              break;
            }
          }

          break;
        }

        case 'Antigravity':
        case 'AntigravitySlow': {
          // Can transition between anti-gravity modes
          if (this.robot.getWristButtonStates()[0]) {
            this.state = {
              ...this.state,
              // double tapped button sets second bool to true
              kind: this.robot.getWristButtonStates()[1]
                ? 'AntigravitySlow'
                : 'Antigravity',
            };
          }

          if (!this.robot.getWristButtonStates()[0]) {
            this.state = {
              kind: 'Idle',
              kinematicState: this.state.kinematicState,
              configuration: this.state.configuration,
              failureState: this.state.failureState,
              cannotStartReasons: this.cannotStartReasons(),
              validToolDirections: ALL_TOOL_MOTIONS_INVALID,
            };
          }

          break;
        }
        case 'Failure': {
          if (
            this.state.failureState.isRecoverableWithWristButton &&
            this.robot.getWristButtonStates()[0]
          ) {
            await this.recover(true);
          }

          break;
        }
        default: {
          break;
        }
      }

      this.lastStateUpdateTime = now;
    } catch (error) {
      if (this.state.kind !== 'Failure') {
        log.error(
          ns`internalFailure`,
          'Internal Failure in RoutineRunner update',
          error,
        );

        this.fail({
          failure: { kind: FailureKind.InternalFailure },
          failureReason: `Failed to update: ${error.message}`,
          stepID: this.currentStepID,
          error,
        });
      }
    }
  }

  private lastCollisionCheck: ForwardKinematicsRequest | null = null;

  private lastCollisionCheckWasColliding: boolean = false;

  /**
   * Looks for collisions and marks state as failure if there are collisions.
   *
   * Returns whether collisions were found.
   */
  private async checkCollisions(): Promise<boolean> {
    const jointAngles = this.getJointAngles();

    // If we're in a state of failure, we don't need to check for collisions.
    // Except if it's a MotionPlannerFailure. In that case, we want to retry
    // the motion planner until it succeeds, at which point we can resolve
    // the failure
    const isInMotionPlannerFailure =
      this.state.kind === 'Failure' &&
      this.state.failureState.failure.kind === 'MotionPlannerFailure';

    if (this.state.kind === 'Failure' && !isInMotionPlannerFailure) {
      return false;
    }

    if (this.state.kind === 'Recovering') {
      return false;
    }

    const request: ForwardKinematicsRequest = {
      gripperOpenness: 1,
      jointAngles,
      checkValidity: true,
    };

    // if the request has already been checked, don't check collisions
    if (isEqual(this.lastCollisionCheck, request)) {
      return this.lastCollisionCheckWasColliding;
    }

    try {
      this.lastCollisionCheck = request;

      const { isColliding, collisions } =
        await this.motionPlanner.forwardKinematics(request);

      if (typeof isColliding === 'boolean') {
        this.lastCollisionCheckWasColliding = isColliding;
      }

      if (isInMotionPlannerFailure) {
        await this.recover();
      }

      if (isColliding) {
        // we need recovery if we are in this state
        await this.fail({
          failure: {
            kind: FailureKind.CollisionFailure,
            collisions: collisions ?? [],
            jointAngles,
          },
          failureReason: 'Arm has encountered a collision',
        });
      }

      this.removePauseKind('motionPlannerFailure');

      return isColliding ?? false;
    } catch (error) {
      // Invalidate the last collision check because it didn't actually
      // give back meaningful information.
      this.lastCollisionCheck = null;

      await this.pauseOrFail({
        failureReason: 'Failed to check for collisions',
        failure: {
          kind: FailureKind.MotionPlannerFailure,
          request: {
            method: 'forwardKinematics',
            args: [request],
          },
        },
        error,
      });

      return false;
    }
  }

  /**
   * Find the path of a step id in a routine.
   *
   * Returns null if the step doesn't exist
   *
   * @internal
   */
  // @ts-ignore (unused)
  private static getStepPath(
    routine: Routine,
    stepID: string,
  ): Array<string> | null {
    // recursive
    function lookForStep(steps: Routine['steps']): Array<string> | null {
      for (const step of steps) {
        if (step.id === stepID) {
          return [step.id];
        }

        if (step.steps) {
          const subpath = lookForStep(step.steps);

          if (subpath) {
            return [...subpath, step.id];
          }
        }
      }

      return null;
    }

    return lookForStep(routine.steps);
  }

  /**
   * @throws if the routine isn't loaded
   * @throws if the runner is in the wrong state
   */
  public async playRoutine({
    firstArmMoveIsGuidedMode = false,
    isPreflightTestRun = false,
    guidedMode = false,
    speedProfile,
    shouldPauseWhenCompleted = false,
  }: PlayRoutineArgs) {
    await this.checkCollisions();

    const startTime = new Date();
    this.update();
    this.routineContext.resetOnPlayRoutine();

    if (!this.routineContext.loadedRoutineState || !this.steps) {
      throw new Error('Routine is not loaded');
    }

    if (this.state.kind !== 'Idle') {
      throw new Error(`Cannot play routine while in state ${this.state.kind}`);
    }

    if (this.state.kinematicState.brakesEngaged) {
      throw new Error('Robot needs to unbrake');
    }

    log.info(
      ns`playRoutine`,
      `Playing routineID=${this.routineContext.loadedRoutineState.id} speedProfile=${speedProfile}`,
      { routineId: this.routineContext.loadedRoutineState.id, speedProfile },
    );

    this.state = {
      kind: 'RoutineRunning',
      shouldCheckGuidedMode: guidedMode,
      isPaused: false,
      pauseDetails: [],
      kinematicState: this.state.kinematicState,
      currentStepID: this.steps[0].id,
      configuration: {
        ...this.state.configuration,
        routineSpeedProfile: speedProfile,
      },
      failureState: this.state.failureState,
      variables: this.routineContext.getAllVariables(),
      startTime: startTime.toISOString(),
      runTimeSeconds: 0,
      shouldNextArmMoveBeGuidedMode: firstArmMoveIsGuidedMode,
      isPreflightTestRun,
    };

    this.instructionID += 1;
    const { instructionID } = this;
    await this.untilStill(5000);

    if (instructionID !== this.instructionID) {
      return;
    }

    const cannotStartReasons = this.cannotStartReasons();

    if (cannotStartReasons.length > 0) {
      let failureReason: string = '';

      if (cannotStartReasons.length === 1) {
        failureReason = humanReadablePauseKind(cannotStartReasons[0]);
      } else {
        failureReason = `Cannot play routine because: ${cannotStartReasons
          .map(humanReadablePauseKind)
          .join(', ')}`;
      }

      this.fail({
        failure: { kind: FailureKind.IOFailure },
        failureReason,
      });

      return;
    }

    const cancelAsynchronousStepFailureListener =
      this.routineContext.onAsynchronousStepFailure(({ stepID }) => {
        if (this.instructionID !== instructionID) {
          cancelAsynchronousStepFailureListener();

          return;
        }

        this.stop(`Routine step ${stepID} failed asynchronously.`);
        cancelAsynchronousStepFailureListener();
      });

    this.hasEncounteredStartStep = false;
    this.currentlyRunningSteps = [];

    await this.initializeEnvironmentVariables();

    while (true) {
      try {
        await this.playSteps({
          steps: this.steps,
          guidedMode,
          hasBeenPreempted: () => {
            return (
              this.state.kind !== 'RoutineRunning' ||
              instructionID !== this.instructionID
            );
          },
        });
      } finally {
        this.currentlyRunningSteps = [];
        cancelAsynchronousStepFailureListener();
      }

      if (this.instructionID !== instructionID) {
        return;
      }

      if (!this.hasEncounteredStartStep) {
        const err = new Error(
          `Could not find start step ${this.routineContext.loadedRoutineState.startStepID}`,
        );

        this.fail({
          failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
          failureReason: `Loaded routine has specified a start step ID that could not be found (${this.routineContext.loadedRoutineState.startStepID})`,
        });

        throw err;
      }

      if (this.state.kind !== 'RoutineRunning') {
        return;
      }

      if (shouldPauseWhenCompleted) {
        if (this.state.isPreflightTestRun) {
          // preflight test run is completed
          this.state = {
            ...this.state,
            isPreflightTestRun: false,
          };
        }

        // pause, next resume will restart the routine
        this.pauseRoutine({
          kind: 'routineCompleted',
          reason: 'The routine completed.',
        });
      } else {
        // return to idle and exit
        this.state = {
          kind: 'Idle',
          kinematicState: this.state.kinematicState,
          configuration: this.state.configuration,
          failureState: this.state.failureState,
          cannotStartReasons: this.cannotStartReasons(),
          validToolDirections: ALL_TOOL_MOTIONS_INVALID,
        };

        return;
      }
    }
  }

  public async skipPreflightTestRun() {
    if (
      this.state.kind !== 'RoutineRunning' ||
      !this.state.isPreflightTestRun
    ) {
      throw new Error('Test run must be running to skipPreflightTestRun');
    }

    this.state = {
      ...this.state,
      isPreflightTestRun: false,
    };
  }

  /**
   * Changes configuration state at runtime to allow routines to run faster or slower.
   * Speed profile provides limits which will not be exceeded even when a routine specifies
   * a faster plan in the routine configuration.
   *
   * @throws if RoutineRunner instance is not paused
   * @category Routine Playback
   */
  public async changeRoutineSpeedProfile(speedProfile: SpeedProfile) {
    this.routineContext.setRoutineSpeedProfile(speedProfile);
  }

  /**
   * Set the antigravity speeds.
   *
   * @throws if RoutineRunner instance is not paused
   * @category Routine Playback
   */
  public async setAntigravitySpeeds(speeds: ArmJointVelocities) {
    this.routineContext.setAntigravitySpeeds(speeds);
  }

  private handleIOStateChange({ ioStateChanged }: IOStateChanged) {
    log.info(ns`io.change`, 'IO State change:', { ioStateChanged });

    if (this.state.kind === 'Idle') {
      this.state = {
        ...this.state,
        cannotStartReasons: this.cannotStartReasons(),
      };

      return;
    }

    if (this.state.kind !== 'RoutineRunning') {
      return;
    }

    const { oldState, newState } = ioStateChanged;

    let reset = false;

    const oldIn = (oldState?.externalToRobotIO ?? sixteen(false)).map(
      toBit,
    ) as Sixteen<Bit>;

    if (!newState) {
      this.pauseRoutine({
        kind: 'ioStateStale',
        isAutoResume: true,
      });

      return;
    }

    this.removePauseKind('ioStateStale');

    const newIn = newState.externalToRobotIO.map(toBit) as Sixteen<Bit>;

    const activePauses: RoutineRunningPauseKind[] = [];

    for (const safeguardRule of this.state.configuration.safeguardRules) {
      const wasTriggered = safeguardRuleIsTriggeredBy(safeguardRule, oldIn);
      const isTriggered = safeguardRuleIsTriggeredBy(safeguardRule, newIn);

      const isPause =
        safeguardRule.kind === 'pausehigh' || safeguardRule.kind === 'pauselow';

      const isReset = safeguardRule.kind === 'reset';

      if (isTriggered) {
        if (isPause) {
          log.info(
            ns`io.pause`,
            `Pausing due to ${safeguardRule.pair} being triggered`,
            { safeguardRule },
          );

          activePauses.push(`ioPauseTriggered_${safeguardRule.pair}`);
        } else if (!wasTriggered && isReset) {
          log.info(
            ns`reset.triggered`,
            `Reset triggered (${safeguardRule.pair})`,
            { safeguardRule },
          );

          reset = true;
        }
      } else if (wasTriggered && !isTriggered) {
        if (safeguardRule.isAutoReset && isPause) {
          log.info(
            ns`io.pause`,
            `Auto-resetting safeguard pair ${safeguardRule.pair}`,
            { safeguardRule },
          );

          this.removePauseKind(`ioPauseTriggered_${safeguardRule.pair}`);

          log.debug(ns`io.paused`, 'Pause details:', this.state.pauseDetails);
        }
      }
    }

    if (reset) {
      log.info(ns`io.pause.reset`, 'Resetting IO pause');

      for (const { pair } of this.state.configuration.safeguardRules) {
        this.removePauseKind(`ioPauseTriggered_${pair}`);
      }
    }

    for (const pauseKind of activePauses) {
      this.pauseRoutine({
        kind: pauseKind,
        isAutoResume: true,
        reason: humanReadablePauseKind(pauseKind),
      });
    }
  }

  private handleButtonStateChange({
    buttonStateChangeEvent: event,
  }: ButtonStateChangeEvent) {
    log.info(ns`buttonState.changed`, 'Button state change:', { event });

    const isDownPress = event.newButtonState;

    const buttonTypes = {
      power: 0,
      playPause: 1,
      control: 2,
    } as const;

    if (event.buttonLocation === 'controlBox') {
      if (isDownPress && event.buttonIndex === buttonTypes.playPause) {
        this.toggleUserPlayPause();
      }

      if (isDownPress && event.buttonIndex === buttonTypes.control) {
        this.togglePrimaryIO();
      }
    }
  }

  private async togglePrimaryIO() {
    const io = this.robot.getDigitalOutputs();
    const newLevel = io[0] ? 'low' : 'high';

    try {
      await this.robot.setOutputIO([
        {
          label: 'Output 1',
          level: newLevel,
        },
      ]);
    } catch (e) {
      log.error(ns`io.togglePrimary.failure`, 'Failed to toggle primary IO', e);
    }
  }

  private toggleUserPlayPause() {
    if (this.state.kind !== 'RoutineRunning') {
      log.info(
        ns`playPause.ignored`,
        'Cannot toggle play/pause when not running a routine',
        {
          stateKind: this.state.kind,
        },
      );

      return;
    }

    if (this.state.isPaused) {
      this.resumeRoutine();
    } else {
      this.pauseRoutine({
        kind: 'user',
      });
    }
  }

  /**
   * Determine reasons why a routine should not be started.
   * Uses `pauseKind` because these are all reasons a routine would be paused
   * rather than failed.
   */
  private cannotStartReasons(): Array<RoutineRunningPauseKind> {
    const pauseKinds = new Set<RoutineRunningPauseKind>();

    if (this.state.kinematicState.safeguardState === 'eStop') {
      pauseKinds.add('eStopTriggered');
    }

    if (this.state.kinematicState.brakesEngaged) {
      pauseKinds.add('brakesEngaged');
    }

    for (const safeguardRule of this.state.configuration.safeguardRules) {
      const isTriggered = safeguardRuleIsTriggeredBy(
        safeguardRule,
        this.robot.getDigitalInputs().map(toBit) as Sixteen<Bit>,
      );

      if (!isTriggered) {
        continue;
      }

      switch (safeguardRule.kind) {
        case 'pausehigh':
        case 'pauselow': {
          pauseKinds.add(`ioPauseTriggered_${safeguardRule.pair}`);

          break;
        }
        case 'estop': {
          pauseKinds.add('eStopTriggered');
          pauseKinds.add(`ioPauseTriggered_${safeguardRule.pair}`);
          break;
        }
        default:
          break;
      }
    }

    return Array.from(pauseKinds.values());
  }

  /**
   * Plays through a series of routine steps
   * @category Routine Playback
   */
  private async playSteps(args: {
    steps: Array<Step>;
    guidedMode: boolean;
    /**
     * Provided on internal recursions to stop the LoopStep from continuing.
     *
     * @internal
     */
    breakOutOfLoop?: () => void;

    /**
     * Provided on internal recursions to continue the LoopStep.
     *
     * @internal
     */
    continueLoop?: () => void;

    /**
     * Determine whether the steps have been preempted and whether any more steps
     * should be played.
     *
     * Allows the caller to stop execution of steps in the middle, for example
     * if the user stops the routine or if a `LoopControl` `Continue` step occurs.
     *
     * @internal
     */
    hasBeenPreempted(): boolean;

    /**
     * If `true`, won't set `currentStepID` in RoutineRunnerState
     */
    skipSettingCurrentStepID?: boolean;

    /**
     * If running a step with substeps such as Loop, we pass in the id of the
     * for logging purposes
     */
    parentStepID?: string;
  }) {
    const playStepsStartTime = Date.now();

    if (this.state.kind !== 'RoutineRunning') {
      let metadata = `(${this.state.kind})`;

      if (this.state.failureState?.failedStep) {
        metadata = `(Failed on step: ${this.state.failureState.failedStep})`;
      }

      throw new Error(
        `Routine not running when calling playSteps. ${metadata}`,
      );
    }

    const {
      steps,
      guidedMode,
      breakOutOfLoop,
      continueLoop,
      hasBeenPreempted,
      skipSettingCurrentStepID,
      parentStepID,
    } = args;

    // loop through and play each step
    for (let ii = 0; ii < steps.length; ii += 1) {
      const step = steps[ii];

      try {
        if (hasBeenPreempted()) {
          return;
        }

        if (!this.hasEncounteredStartStep) {
          this.hasEncounteredStartStep =
            step.id === this.routineContext.loadedRoutineState?.startStepID;
        }

        // When `hasEncounteredStartStep` has not yet been set to `true`, we don't
        // actually want to play anything but just traverse through the steps until we
        // encounter it.
        if (!this.hasEncounteredStartStep && !step.substeps) {
          continue;
        }

        const setCurrentStepID = () => {
          if (
            !skipSettingCurrentStepID &&
            this.state.kind === 'RoutineRunning'
          ) {
            this.state = {
              ...this.state,
              currentStepID: step.id,
            };
          }
        };

        setCurrentStepID();

        while (!hasBeenPreempted() && this.state.isPaused) {
          await wait(STILL_GOING_CHECK_INTERVAL_MS);
        }

        if (hasBeenPreempted()) {
          return;
        }

        log.info(
          ns`stepPlay.beginning`,
          `Beginning step ${step.getStepKind()} (${step.id})`,
          {
            kind: step.getStepKind(),
            id: step.id,
          },
        );

        this.currentlyRunningSteps = [step, ...this.currentlyRunningSteps];

        let isFailed = false;

        this.checkCollisions();

        await step.play({
          guidedMode,
          breakOutOfLoop,
          continueLoop,
          hasBeenPreempted,
          hasEncounteredStartStep: () => this.hasEncounteredStartStep,
          fail: async (failure) => {
            isFailed = true;

            if (!hasBeenPreempted()) {
              await this.pauseOrFail({
                ...failure,
                stepID: step.id,
              });
            }
          },
          playSubSteps: async (subArgs) => {
            if (step.substeps) {
              await this.playSteps({
                steps: step.substeps,
                guidedMode,
                breakOutOfLoop,
                continueLoop,
                hasBeenPreempted,
                skipSettingCurrentStepID,
                parentStepID: step.id,
                ...subArgs,
              });

              setCurrentStepID();
            }
          },
          pauseRoutine: (pauseDetails) => {
            return this.pauseRoutine(pauseDetails);
          },
        });

        this.currentlyRunningSteps = this.currentlyRunningSteps.filter(
          (runningStep) => runningStep !== step,
        );

        if (hasBeenPreempted()) {
          log.error(
            ns`stepPlay.preempted`,
            `Step ${step.getStepKind()} (${step.id}) preempted`,
            {
              kind: step.getStepKind(),
              id: step.id,
            },
          );

          return;
        }

        const stepElapsedTime = step.timers.full.getTime() / 1000;

        if (isFailed) {
          log.error(
            ns`stepPlay.failed`,
            `Step failed stepKind=${step.getStepKind()}`,
            {
              kind: step.getStepKind(),
              id: step.id,
              stepFailedTime: stepElapsedTime,
            },
          );

          ii -= 1; // if we paused, we want to resume on the same step
        } else {
          log.info(
            ns`stepPlay.succeeded`,
            `Step succeeded stepKind=${step.getStepKind()}`,
            {
              kind: step.getStepKind(),
              id: step.id,
              stepSucceededTime: stepElapsedTime,
            },
          );
        }

        log.info(ns`step.stopped`, `Calling stop on Step ${step.id}`, {
          kind: step.getStepKind(),
          id: step.id,
        });

        await step.stop();
      } catch (error) {
        if (hasBeenPreempted()) {
          log.error(
            ns`stepPlay.preempted`,
            `Step ${step.getStepKind()} (${
              step.id
            }) failed internally but was preempted`,
            {
              kind: step.getStepKind(),
              id: step.id,
            },
          );

          return;
        }

        log.error(
          ns`playSteps.InternalFailure`,
          'Internal Failure in playSteps',
          { error, step },
        );

        // steps are never supposed to throw errors, but it could be an internal bug
        this.fail({
          failure: { kind: FailureKind.InternalFailure },
          failureReason: `Step ${step.getStepKind()} failed internally`,
          stepID: step.id,
          error,
        });

        return;
      }
    }

    if (parentStepID) {
      log.info(
        ns`stepPlay.parent.finished`,
        `Step finished parentStepID=${parentStepID}`,
        {
          parentStepID,
          parentStepTime: (Date.now() - playStepsStartTime) / 1000,
        },
      );
    }
  }

  private async initializeEnvironmentVariables(): Promise<void> {
    const environmentVariables =
      this.routineContext.loadedRoutineState?.environmentVariables;

    if (!environmentVariables) {
      return;
    }

    for (const { id, initialValue } of environmentVariables) {
      if (initialValue) {
        const value = await this.routineContext.evaluateExpression(
          initialValue,
        );

        this.routineContext.setVariableState(id, {
          stepKind: 'EnvironmentVariable',
          value,
        });
      }
    }
  }

  private async stopArmAndGripper(): Promise<void> {
    return this.routineContext.stopArmAndGripper();
  }

  /**
   * if stopped in the middle of recovery, restore the failure
   */
  private restoreFailureStateKind() {
    if (this.state.kind !== 'Failure' && this.state.failureState) {
      this.state = {
        kind: 'Failure',
        kinematicState: this.state.kinematicState,
        configuration: this.state.configuration,
        failureState: this.state.failureState,
      };
    }
  }

  /**
   * Stops the current action entirely (no failure in itself)
   */
  public async stop(reasonForStopping = 'stopped by client') {
    log.info(ns`stopping`, `Stopping because: ${reasonForStopping}`, {
      reasonForStopping,
      stateKind: this.state.kind,
      currentlyRunningSteps: this.currentlyRunningSteps.map((st) => ({
        id: st.id,
        name: st.getStepKind(),
      })),
    });

    this.instructionID += 1;
    this.startOfGuidedMode = null;

    switch (this.state.kind) {
      case 'RoutineRunning': {
        this.currentlyRunningSteps.forEach((runningStep) => {
          runningStep.stop();
        });

        this.state = {
          kind: 'Idle',
          kinematicState: this.state.kinematicState,
          configuration: this.state.configuration,
          failureState: this.state.failureState,
          cannotStartReasons: this.cannotStartReasons(),
          validToolDirections: ALL_TOOL_MOTIONS_INVALID,
        };

        this.update();

        break;
      }

      case 'RunningAdHocCommand': {
        this.state = {
          kind: 'Idle',
          kinematicState: this.state.kinematicState,
          configuration: this.state.configuration,
          failureState: this.state.failureState,
          cannotStartReasons: this.cannotStartReasons(),
          validToolDirections: ALL_TOOL_MOTIONS_INVALID,
        };

        // run preflight checks
        this.update();
        break;
      }

      case 'Recovering': {
        this.restoreFailureStateKind();
        break;
      }

      default: {
        // nothing to stop
        break;
      }
    }

    await this.stopArmAndGripper().catch((error) => {
      log.error(
        ns`stopArmAndGripper.failure`,
        'Internal Failure in stopArmAndGripper',
        error,
      );

      this.fail({
        failure: { kind: FailureKind.InternalFailure },
        failureReason: 'Internal communication failure.',
        error,
      });
    });
  }

  /**
   * Stops the current action with a failure.
   *
   * Mostly a proxy for a synchronous RoutineContext.fail.
   */
  private async fail(failure: StepFailure) {
    if (this.state.kind === 'Failure') {
      // Idempotency check: If already in failed state, no need to re-fail
      return;
    }

    if (this.state.failureState?.recoveryType === 'ManualRecoveryMode') {
      // failures during manual recovery do not exit the recovery
      return;
    }

    log.error(
      ns`fail`,
      `Step ${failure.stepID} failed: ${failure.failureReason} (${failure.failure})`,
      { failure },
    );

    if (failure.stepID) {
      this.stop(`Step ${failure.stepID} failed: ${failure.failureReason}`);
    } else {
      this.stop(`Failed: ${failure.failureReason}`);
    }

    return this.routineContext.fail(failure);
  }

  public async emergencyStop(source: string) {
    await this.sdk.brakes.estop(source);
  }

  /**
   * Pauses the running routine
   *
   * @category Routine Playback
   */
  public async pauseRoutine(pauseDetails: RoutineRunningPauseDetails) {
    if (this.state.kind !== 'RoutineRunning') {
      throw new Error('Routine must be running to pause');
    }

    if (!this.state.isPaused) {
      log.info(ns`routine.pauseRoutine`, 'Pause routine', {
        pauseDetails,
        currentlyRunningSteps: this.currentlyRunningSteps.map((st) => ({
          id: st.id,
          name: st.getStepKind(),
        })),
      });

      this.stopArmAndGripper().catch(() => {});

      this.currentlyRunningSteps.forEach((runningStep) => {
        runningStep.pause();
      });
    }

    this.state = {
      ...this.state,
      isPaused: true,
      pauseDetails: this.state.pauseDetails
        .filter((d) => d.kind !== pauseDetails.kind)
        .concat(pauseDetails),
    };
  }

  /**
   * Resumes a paused routine
   *
   * @category Routine Playback
   */
  public resumeRoutine() {
    if (this.state.kind !== 'RoutineRunning') {
      throw new Error('Routine must be running and paused to resume');
    }

    if (!this.state.isPaused) {
      throw new Error('Routine must be paused to unpause');
    }

    const cannotStartReasons = this.cannotStartReasons();

    if (cannotStartReasons.length > 0) {
      if (cannotStartReasons.length === 1) {
        throw new Error(humanReadablePauseKind(cannotStartReasons[0]));
      }

      throw new Error(
        `Cannot resume routine because: ${cannotStartReasons
          .map(humanReadablePauseKind)
          .join(', ')}`,
      );
    }

    log.info(ns`routine.resume`, 'Resume routine', {
      currentlyRunningSteps: this.currentlyRunningSteps.map((st) => ({
        id: st.id,
        name: st.getStepKind(),
      })),
    });

    [...this.currentlyRunningSteps]
      .reverse() // resume in the same order the steps were started
      .forEach((runningStep) => {
        runningStep.resume();
      });

    // Set unpaused state _after_ resuming the steps:
    // If a resume throws an error, then state will remain paused
    this.state = {
      ...this.state,
      isPaused: false,
      pauseDetails: [],
    };
  }

  private async pauseOrFail(failure: StepFailure): Promise<void> {
    try {
      if (this.state.kind === 'RoutineRunning') {
        const pauseKind: RoutineRunningPauseKind | undefined = (() => {
          switch (failure.failure.kind) {
            case FailureKind.BotmanHeartbeatLost:
              return 'botmanHeartbeatLost';
            case FailureKind.CollisionFailure:
            case FailureKind.TorqueCollisionFailure:
              return 'collision';
            case FailureKind.EStopTriggered:
              return 'eStopTriggered';
            case FailureKind.ExecutionFailure:
              switch (failure.failure.motionResult?.kind) {
                case 'collision':
                  return 'collision';
                case 'eStopTriggered':
                  return 'eStopTriggered';
                case 'controlSystemEvent':
                  return 'controlSystemEvent';
                case 'botmanHeartbeatLost':
                  return 'botmanHeartbeatLost';
                case 'ioStateChanged':
                  // calls pauseRoutine internally if relevant
                  this.handleIOStateChange(failure.failure.motionResult);

                  return undefined;
                default:
                  return undefined;
              }
            case FailureKind.MotionPlannerFailure:
              return 'motionPlannerFailure';
            case FailureKind.ControlSystemFailure:
              return 'controlSystemEvent';
            default:
              return undefined;
          }
        })();

        if (pauseKind) {
          await this.pauseRoutine({
            kind: pauseKind,
            reason: failure.failureReason,
            isAutoResume: this.isAutoResumePauseKind(pauseKind),
          });

          return;
        }
      }

      await this.fail(failure);
    } catch (error) {
      log.error(ns`pauseOrFail.error`, 'Error in pauseOrFail', error);
    }
  }

  private isAutoResumePauseKind(pauseKind: RoutineRunningPauseKind): boolean {
    switch (pauseKind) {
      case 'motionPlannerFailure':
      case 'botmanHeartbeatLost':
        return true;
      default:
        return false;
    }
  }

  private removePauseKind(pauseKind: RoutineRunningPauseKind): void {
    if (
      this.state.kind === 'RoutineRunning' &&
      this.state.isPaused &&
      this.state.pauseDetails.some((d) => d.kind === pauseKind)
    ) {
      if (this.state.pauseDetails.length === 1) {
        // this was the only pause reason, so can resume
        this.resumeRoutine();
      } else {
        this.state = {
          ...this.state,
          pauseDetails: this.state.pauseDetails?.filter(
            (d) => d.kind !== pauseKind,
          ),
        };
      }
    }
  }

  /**
   * Returns data that can be used to show the robot's status in the UI,
   * generate a human readable message, and make programmatic decisions about
   * if the robot is ready to play a new routine, etc.
   */
  public getState(): RoutineRunnerState {
    this.update();

    return this.state;
  }

  /**
   * Returns the currently loaded routine
   */
  public getLoadedRoutineState(): LoadedRoutineState | undefined {
    return this.routineContext.loadedRoutineState;
  }

  public setEnvironmentVariable(variableId: string, value: any) {
    this.routineContext.setVariableState(variableId, {
      stepKind: 'EnvironmentVariable',
      value,
    });
  }

  /**
   * Get the absolute maximum values that can be provided to `setRobotSpeedLimits`.
   *
   * @category Configuration
   */
  public getGlobalSpeedLimits(): {
    maxJointVelocities: ArmJointVelocities;
    maxJointAccelerations: ArmJointAccelerations;
    maxTooltipSpeed: number;
  } {
    return {
      maxJointVelocities: ABSOLUTE_MAX_JOINT_SPEEDS,
      maxJointAccelerations: ABSOLUTE_MAX_JOINT_ACCELERATIONS,
      maxTooltipSpeed: ABSOLUTE_MAX_TOOLTIP_SPEED,
    };
  }

  /**
   * Set information about the payload for use in dynamics and planning.
   *
   * @param payload Information about the payload
   *
   * @category Configuration
   */
  public setRobotPayload(payload: PayloadState) {
    this.routineContext.setPayload(payload);
  }

  /**
   * Tell a step (`WaitForConfirmation`) that it's valid to continue
   *
   * @returns Whether that step was actually waiting for confirmation
   */
  public confirmStep(stepID: string): boolean {
    const step = this.currentlyRunningSteps.find(
      (runningStep) => runningStep.id === stepID,
    );

    if (!step) {
      return false;
    }

    if (!(step instanceof WaitForConfirmationStep)) {
      return false;
    }

    step.confirm();
    this.resumeRoutine();

    return true;
  }

  /**
   * Call this frequently when doing an ad hoc command or a guided/slower routine run.
   *
   * Failing to call this frequently enough while doing a guided action will stop the action
   * and move the routine runner to a Failure state. See
   * [[this.guidedModeTimeoutMS]]
   */
  public validateGuidedMode() {
    if (!this.startOfGuidedMode) {
      this.startOfGuidedMode = new Date();
    }

    this.lastGuidedModeValidation = new Date();
  }

  /**
   * Helper to determine whether the robot arm is in the joint limits.
   *
   * @returns An array describing the limit violations for each joint.
   *          If error is `null`, the limit was ok.
   *          If it's positive, it was above the maximum allowed position.
   *          If it's negative, it was below the minimum allowed position.
   */
  private jointLimitViolations(): Six<{
    error: number | null;
    min: number;
    max: number;
    actual: number;
  }> {
    const angles = this.getJointAngles();
    const limits = this.routineContext.getJointLimits();

    return limits.map(({ min, max }, jointIndex) => {
      const angle = angles[jointIndex];

      const entry = {
        error: null as number | null,
        min,
        max,
        actual: angle,
      };

      if (angle < min) {
        entry.error = angle - min;
      } else if (angle > max) {
        entry.error = angle - max;
      }

      return entry;
    }) as Six<{
      error: number | null;
      min: number;
      max: number;
      actual: number;
    }>;
  }

  private isOutOfJointLimits(): boolean {
    return this.jointLimitViolations().some((n) => n.error != null);
  }

  private getJointAngles(): ArmJointPositions {
    return this.routineContext.getJointAngles();
  }

  /**
   * Have we received a validateGuidedMode() call during the proper window?
   */
  private isGuidedModeValid(): boolean {
    const now = new Date().getTime();
    const diff = now - this.lastGuidedModeValidation.getTime();

    return diff < this.guidedModeTimeoutMS;
  }

  private untilStill(timeoutMS = Number.POSITIVE_INFINITY): Promise<void> {
    const velocities = this.state.kinematicState.jointVelocities;
    let tooFast = false;

    for (let ii = 0; ii < velocities.length; ii += 1) {
      if (Math.abs(velocities[ii]) > 0.1) {
        tooFast = true;
      }
    }

    if (!tooFast) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      let lastAngles: ArmJointPositions = six(0);
      const start = Date.now();

      const interval = setInterval(() => {
        if (Date.now() - start > timeoutMS) {
          clearInterval(interval);

          reject(
            new Error(
              `Timeout waiting for the robot to be still in ${timeoutMS}ms`,
            ),
          );

          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const velocities = this.state.kinematicState.jointVelocities;

        for (let ii = 0; ii < velocities.length; ii += 1) {
          if (Math.abs(velocities[ii]) > 0.1) {
            return;
          }
        }

        const angles = this.state.kinematicState.jointAngles;

        for (let ii = 0; ii < angles.length; ii += 1) {
          if (
            Math.abs(angles[ii] - lastAngles[ii]) > HOLD_STEADY_POSITION_EPSILON
          ) {
            lastAngles = angles;

            return;
          }
        }

        clearInterval(interval);
        resolve();
      }, 100);
    });
  }

  public async setJointSafetyLimits(limits: JointSafetyLimits) {
    await this.routineContext.setJointSafetyLimits(limits);
  }

  public async setAccelerationCollisionThresholds(
    thresholds: AccelerationCollisionThresholds,
  ) {
    await this.routineContext.setAccelerationCollisionThresholds(thresholds);
  }

  public setJerkLimit(jerkLimit: JerkLimit) {
    this.routineContext.setJerkLimit(jerkLimit);
  }

  public async setSafeguardRules(safeguardRules: Array<SafeguardRule>) {
    this.state = {
      ...this.state,
      configuration: {
        ...this.state.configuration,
        safeguardRules,
      },
    };

    this.robot.setSafeguardRules(safeguardRules);
  }

  public setLEDPattern(colorPattern: LEDColorPattern): Promise<boolean> {
    return this.robot.setLEDPattern(colorPattern);
  }

  public visionMethodRunner: VisionMethodRunner;

  public setTCPOffsetOption(tcpOffsetOption: number) {
    this.routineContext.setTCPOffsetOption(tcpOffsetOption);
  }

  public unbrakeJoint(jointNumber: JointNumber) {
    return this.robot.unbrakeJoint(jointNumber);
  }

  public clearMotionPlanCache() {
    log.info('RoutineRunner', 'clearMotionPlanCache');
    this.motionPlanner.clearMotionPlanCache();
  }
}
