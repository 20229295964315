import type { RoutineData } from '@sb/feathers-types';
import type { Routine } from '@sb/remote-control/types';
import { Space } from '@sb/routine-runner';
import {
  convertStepConfigurations,
  convertStepSummary,
  getStepActionRequired,
  listAllSteps,
} from '@sbrc/utils';

import { convertRoutineSummary } from './convertRoutineSummary';
import { migrateStepConfiguration } from './migrateStepConfiguration';
import { getVirtualSpaceItems } from './virtualSpaceItems';

export function convertRoutineResponse(
  data: RoutineData,
): Routine.ConvertedResponse {
  const summary = convertRoutineSummary(data);

  const steps = convertStepSummary(data.steps);

  const stepConfigurations = convertStepConfigurations(
    data.id,
    steps,
    data.stepConfigurations ?? undefined,
  );

  for (const step of Object.values(stepConfigurations)) {
    migrateStepConfiguration(step);
  }

  const space: Space.Item[] = [];

  if (data.space) {
    for (const spaceItem of data.space) {
      const parsedItem = Space.Item.safeParse(spaceItem);

      if (parsedItem.success) {
        space.push(parsedItem.data);
      }
    }
  }

  space.push(...getVirtualSpaceItems(stepConfigurations));

  const routine: Routine.ConvertedResponse = {
    ...summary,
    configurationUpdatedAt: new Date(
      data.configurationUpdatedAt ?? data.createdAt,
    ),
    currentEditorID: null,
    environmentVariables: data.environmentVariables ?? [],
    equipment: [], // will be merged in from equipment service
    mainLoopStepID: data.mainLoopStepID ?? null,
    space,
    steps,
    stepConfigurations,
    // calculated below..
    actionRequiredByStepID: {},
    isActionRequired: false,
  };

  for (const step of listAllSteps(steps)) {
    const actionRequired = getStepActionRequired(step, routine);

    if (actionRequired) {
      routine.actionRequiredByStepID[step.id] = actionRequired;
      routine.isActionRequired = true;
    }
  }

  return routine;
}
