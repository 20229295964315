import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { OnRobotConnectionConfiguration } from '@sb/integrations/gripper-general/types/Configuration';

import {
  OR3FG15FingerPosition,
  OR3FG15_DEFAULT_TCP_TRANSFORM,
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_FINGER_POSITION_DEFAULT,
  OR3FG15_SHORTNAME,
} from '../constants';

export const OnRobot3FG15FingerConfiguration = zod
  .object({
    lengthInMeters: zod.number().default(OR_3FG15_DEFAULT_FINGER_LENGTH),
    mountingPosition: OR3FG15FingerPosition.default(
      OR_3FG15_FINGER_POSITION_DEFAULT,
    ),
    offsetInMeters: zod.number().default(OR_3FG15_FINGERTIP_DIAMETER_DEFAULT),
  })
  .default({});

export type OnRobot3FG15FingerConfiguration = zod.infer<
  typeof OnRobot3FG15FingerConfiguration
>;

export const OnRobot3FG15GrippersConfiguration = zod.object({
  kind: zod.literal('OnRobot3FG15'),
  name: zod.string().default(OR3FG15_SHORTNAME),
  fingerConfiguration: OnRobot3FG15FingerConfiguration,
  connectionOptions: OnRobotConnectionConfiguration,
  tcpTransform: CartesianPose.default(OR3FG15_DEFAULT_TCP_TRANSFORM),
});

export type OnRobot3FG15GrippersConfiguration = zod.infer<
  typeof OnRobot3FG15GrippersConfiguration
>;

export const ON_ROBOT_3FG15_GRIPPERS_CONFIGURATION_DEFAULT =
  OnRobot3FG15GrippersConfiguration.parse({ kind: 'OnRobot3FG15' });
