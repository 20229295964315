import type { RoutineVariable, Step } from '@sb/remote-control/types';
import type { IOLevel } from '@sb/routine-runner';

/** Message displayed to users when a step hasn't been configured yet. */
export const ACTION_REQUIRED_STEP_MESSAGE_DEFAULT =
  'This step needs to be configured.';

export const DECORATOR_STEP_LIST: Step.Kind[] = [
  'AddOffset',
  'RunInBackground',
  'If',
  'Loop',
  'HaasControlRegion',
];

// steps based on integration. Integration steps only show when integration enabled
export const INTEGRATION_STEP_LIST: Step.Kind[] = [
  'ActuateGripper',
  'ActuateScrewdriver',
  'ActuateVacuum',
];

export const LOOP_STEP_FIXED_LOOP_MIN = 1;

export const STEP_NAME_BY_STEP_KIND: Record<Step.Kind, string> = {
  ActuateGripper: 'Actuate gripper',
  ActuateScrewdriver: 'Actuate screwdriver',
  ActuateVacuum: 'Actuate vacuum',
  AddOffset: 'Add offset',
  AnticipatePayload: 'Set payload',
  Classify: 'Classify',
  Locate: 'Locate',
  RunInBackground: 'Run in background',
  HaasRunProgram: 'Run Haas program',
  HaasControlRegion: 'Monitor Haas machine',
  If: 'If',
  Loop: 'Loop',
  LoopControl: 'Restart loop',
  MoveArmTo: 'Move arm',
  NetworkRequest: 'Network request',
  PressButton: 'Press button',
  SetEnvironmentVariable: 'Set variable',
  SetIO: 'Set I/O output',
  Wait: 'Wait',
  WaitForConfirmation: 'Wait for confirmation',
};

/**
 * Each variable kind has a different set of operators.
 * We're list here all supported operators for each
 * variable kind.
 */
export const OPERATOR_INFO_LIST_BY_VARIABLE_KIND: Record<
  RoutineVariable.Kind,
  RoutineVariable.OperatorInformation[]
> = {
  boolean: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
  composite: [],
  enum: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
  expression: [{ displayName: 'Is True', operator: '==' }],
  number: [
    { displayName: 'Greater Than', operator: '>' },
    { displayName: 'Equal To', operator: '==' },
    { displayName: 'Less Than', operator: '<' },
  ],
  string: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
};

export const IO_STATE_VARIABLES_DEFAULT: RoutineVariable.EnumValue<IOLevel>[] =
  [
    { displayName: 'High', value: 'high' },
    { displayName: 'Low', value: 'low' },
  ];

export const SET_SPEED_SUPPORTED_STEP_KIND: Step.Kind[] = [
  'MoveArmTo',
  'PressButton',
];
