import type { Step } from '@sb/remote-control/types';

import { STEP_NAME_BY_STEP_KIND } from './constants';
import { listAllSteps } from './listAllSteps';

/**
 * Convert step configurations in the db for use in the front end
 * Ensure there is a step configuration for every step in the structure
 */
export function convertStepConfigurations(
  routineID: string,
  steps: Step.ConvertedSummary[],
  configurations: Record<string, Partial<Step.Configuration>> = {},
): Record<string, Step.ConvertedConfiguration> {
  const allSteps = listAllSteps(steps);

  const convertedStepsConfigurations: Record<
    string,
    Step.ConvertedConfiguration
  > = {};

  for (const step of allSteps) {
    convertedStepsConfigurations[step.id] = {
      args: configurations[step.id]?.args as any,
      description: configurations[step.id]?.description || step.description,
      id: step.id,
      name: STEP_NAME_BY_STEP_KIND[step.stepKind],
      routineID,
      stepKind: step.stepKind,
    };
  }

  return convertedStepsConfigurations;
}
