/**
 * This file contains all links used in our application.
 * When creating a new link, please use the following pattern:
 *
 * - Static Links (no query parameters):
 * Create a `const` with the following naming convention:
 * LINK_TO_{SINGULAR_PATHNAME}_{CRUD_ACTION}_PAGE
 *
 * For example:
 * - /routines:     LINK_TO_ROUTINE_LIST_PAGE
 *
 * - Dynamic links:
 * Create a helper function passing the ID using the
 * following naming convention:
 * getLinkTo{SingularPathname}{ActionIfExists}Page
 *
 * For example:
 * - /robots/[robotID]: getLinkToRobotPage(robotID: string) => string
 * - /robots/[robotID]/move: getLinkToRobotMovePage(robotID: string) => string
 */

// help Links
export const LINK_TO_HELP_LIST_PAGE = '/help';
export const getLinkToHelpPage = (articleSlug: string) =>
  `${LINK_TO_HELP_LIST_PAGE}/${articleSlug}`;

// loginAs links
export const getLinkToLoginAsAdminPage = (userID?: string) =>
  `/loginAs/${userID}`;

// logout links
export const LINK_TO_LOGOUT_PAGE = '/logout';

// robots links
export const LINK_TO_ROBOT_LIST_PAGE = '/robots';
export const LINK_TO_MOVE_PAGE = '/';
export const LINK_TO_BOX_PAGE = '/robots/box';
export const LINK_TO_PREFLIGHT_PAGE = '/robots/preflight';
export const LINK_TO_SCENE_PAGE = '/robots/scene';

// routines links
export const LINK_TO_ROUTINE_LIST_PAGE = '/routines';
export const getLinkToRoutinePage = (routineID: string) =>
  `${LINK_TO_ROUTINE_LIST_PAGE}/${routineID}`;

export const getLinkToRoutineCreateGuidedPage = (guide: string) =>
  `${LINK_TO_ROUTINE_LIST_PAGE}/guided-create?guide=${guide}`;

// steps links
export const LINK_TO_STEP_LIST_PAGE = '/steps';

export const getLinkToStepWizardPage = (
  stepID: string,
  robotID: string | undefined,
  routineID: string,
) => {
  let stepWizardLink = `${LINK_TO_STEP_LIST_PAGE}/${stepID}?routineID=${routineID}`;

  if (robotID) {
    stepWizardLink = `${stepWizardLink}&robotID=${robotID}`;
  }

  return stepWizardLink;
};

// users links
export const LINK_TO_USER_LIST_PAGE = '/users';
export const getLinkToUserPage = (userID: string) =>
  `${LINK_TO_USER_LIST_PAGE}/${userID}`;
