import type { Routine, Step } from '@sb/remote-control/types';

import { isInvalidAddOffsetStep } from './isInvalidAddOffsetStep';
import { isInvalidDecoratorStep } from './isInvalidDecoratorStep';
import { isInvalidLoopControlStep } from './isInvalidLoopControlStep';
import { isInvalidMoveArmPositionListStep } from './isInvalidMoveArmPositionListStep';
import { isInvalidOffsetMoveArmStep } from './isInvalidOffsetMoveArmStep';
import { isInvalidWaypointStep } from './isInvalidWaypointStep';

const STEP_VALIDATORS: Array<{
  validator: (
    step: Step.ConvertedSummary,
    stepConfiguration: Step.ConvertedConfiguration | undefined,
    routine: Routine.ConvertedResponse,
  ) => boolean;
  actionRequired: Routine.ActionRequiredStep;
}> = [
  {
    validator: isInvalidDecoratorStep,
    actionRequired: {
      kind: 'invalidConfiguration',
      message: 'Tap row below to add step.',
    },
  },
  {
    validator: isInvalidAddOffsetStep,
    actionRequired: {
      kind: 'invalidConfiguration',
      message: 'Add Offset requires a Move Arm step.',
    },
  },
  {
    validator: isInvalidLoopControlStep,
    actionRequired: {
      kind: 'invalidConfiguration',
      message: 'Move Restart Loop inside a Loop step.',
    },
  },
  {
    validator: isInvalidMoveArmPositionListStep,
    actionRequired: {
      kind: 'invalidConfiguration',
      message: 'Set the Position list linked to Move Arm step.',
    },
  },
  {
    validator: isInvalidOffsetMoveArmStep,
    actionRequired: {
      kind: 'invalidConfiguration',
      message:
        'Move Arm steps inside an Add Offset step may not require exact final joint position, as only the tooltip pose can be guaranteed. Edit the path settings in the step to resolve.',
    },
  },
  {
    validator: isInvalidWaypointStep,
    actionRequired: {
      kind: 'invalidConfiguration',
      message: 'Waypoint steps must have a terminal Move Arm step.',
    },
  },
  {
    validator: (_, stepConfiguration) => !stepConfiguration?.args,
    actionRequired: {
      kind: 'missingConfiguration',
      message: 'This step needs to be configured.',
    },
  },
];

export function getStepActionRequired(
  step: Step.ConvertedSummary,
  routine: Routine.ConvertedResponse,
): Routine.ActionRequiredStep | undefined {
  return STEP_VALIDATORS.find((v) =>
    v.validator(step, routine.stepConfigurations[step.id], routine),
  )?.actionRequired;
}
