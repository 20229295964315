import type { Routine, Step } from '@sb/remote-control/types';

export function isInvalidMoveArmPositionListStep(
  _step: unknown,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
  { space }: Pick<Routine.ConvertedResponse, 'space'>,
): boolean {
  if (
    !stepConfiguration ||
    stepConfiguration.stepKind !== 'MoveArmTo' ||
    !stepConfiguration.args
  ) {
    return false;
  }

  return (
    stepConfiguration.args.targetKind === 'positionList' &&
    !space.some((s) => s.id === stepConfiguration.args?.positionListID)
  );
}
