import type {
  OnRobot3FG15Configuration,
  OR3FG15FingerPosition,
} from '../constants';
import {
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_FINGER_POSITION_DEFAULT,
} from '../constants';
import type { OnRobot3FG15State } from '../State';

export function getOR3FG15ConfigFromRoutineRunnerState(
  gripperState: OnRobot3FG15State | null,
): OnRobot3FG15Configuration {
  return {
    fingerPosition:
      gripperState?.fingerPosition ?? OR_3FG15_FINGER_POSITION_DEFAULT,
    fingertipDiameter:
      gripperState?.fingertipDiameter ?? OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  };
}

export function getOR3FG15FingerConfiguration(
  position?: OR3FG15FingerPosition,
  offset?: number,
): OnRobot3FG15Configuration {
  return {
    fingerPosition: position ?? OR_3FG15_FINGER_POSITION_DEFAULT,
    fingertipDiameter: offset ?? OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  };
}
