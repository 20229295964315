import type { GripperRange } from '../gripper-general';

import { calculateOR2FG7WidthRangeMetersFromDeviceConfig } from './calculateOR2FG7WidthRangeMetersFromDeviceConfig';
import type {
  OR2FG7GripKind,
  OR2FG7StateFingerConfiguration,
} from './constants';
import {
  OR2FG7_FINGER_CONFIGURATION_DEFAULT,
  OR_2FG7_GRIP_KINDS_DEFAULT,
} from './constants';
import type { OnRobot2FG7State } from './State';

export function calculateOR2FG7WidthRangeMetersFromRoutineRunnerState(
  state: OnRobot2FG7State | null | undefined,
  targetGripKind: OR2FG7GripKind = OR_2FG7_GRIP_KINDS_DEFAULT,
  fingerConfig: OR2FG7StateFingerConfiguration = OR2FG7_FINGER_CONFIGURATION_DEFAULT,
): GripperRange {
  if (state && state.isConnected) {
    if (targetGripKind === 'outward') {
      return {
        min: state.minWidth.outer,
        max: state.maxWidth.outer,
      };
    }

    if (targetGripKind === 'inward') {
      return {
        min: state.minWidth.inner,
        max: state.maxWidth.inner,
      };
    }
  }

  // fall back to device configuration as default range
  const deviceConfigurationRange =
    calculateOR2FG7WidthRangeMetersFromDeviceConfig(
      targetGripKind,
      fingerConfig,
    );

  return deviceConfigurationRange;
}
