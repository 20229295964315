import type { CartesianPose } from '@sb/geometry';
import type { ArmJointPositions } from '@sb/motion-planning';

import type { CalibrationData } from './types/CalibrationData';

function distanceInJointSpace(
  a: ArmJointPositions,
  b: ArmJointPositions,
): number {
  return Math.hypot(
    a[0] - b[0],
    a[1] - b[1],
    a[2] - b[2],
    a[3] - b[3],
    a[4] - b[4],
    a[5] - b[5],
  );
}

export function getNearestJointPositionsIndex(
  targetJointPositions: ArmJointPositions,
  jointPositions: ArmJointPositions[],
): number {
  let minDistance: number = Infinity;
  let minJointPositionsIndex: number | null = null;

  for (const [index, jointPosition] of jointPositions.entries()) {
    const distance = distanceInJointSpace(targetJointPositions, jointPosition);

    if (distance < minDistance) {
      minDistance = distance;
      minJointPositionsIndex = index;
    }
  }

  if (minJointPositionsIndex === null) {
    throw new Error('Could not find nearest joint positions');
  }

  return minJointPositionsIndex;
}

/**
 * This uses calibration data to find the offset to apply to the current pose
 * It finds the offset by finding the nearest joint positions in the calibration data
 * and returning the offset associated with that joint position
 * @param jointPositions - The current joint positions of the arm
 * @param CalibrationData - The calibration data to use to find the apporpriate offset
 * @returns The offset to apply to the current pose
 */
export function getCalibrationOffset(
  jointPositions: ArmJointPositions,
  calibrationData: CalibrationData,
): CartesianPose {
  const index = getNearestJointPositionsIndex(
    jointPositions,
    calibrationData.jointPositionsList,
  );

  return calibrationData.offsets[index];
}
