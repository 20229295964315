import * as zod from 'zod';

import type { CustomGripper } from './CustomGripper/implementation/CustomGripper';
import { CustomGripperConfiguration } from './CustomGripper/types/CustomGripperConfiguration';
import { CustomIOConfiguration } from './CustomIO/types/Configuration';
import { EwellixLiftTLTCommand, EwellixLiftTLTState } from './EwellixLiftTLT';
import type { EwellixLiftTLT } from './EwellixLiftTLT/implementation';
import { EwellixLiftTLTConfiguration } from './EwellixLiftTLT/types/Configuration';
import { GripperStateNonNull } from './gripper-general';
import { InvalidConfiguration } from './InvalidConfiguration/types/Configuration';
import { ModbusTCPServerCommand } from './ModbusTCPServer/command';
import type { ModbusTCPServer } from './ModbusTCPServer/implementation/ModbusTCPServer';
import { ModbusTCPServerConfiguration } from './ModbusTCPServer/types/Configuration';
import { NoGripperConfiguration } from './NoGripper/types/Configuration';
import { OnRobot2FG7Command } from './OnRobot2FG7';
import type { OnRobot2FG7 } from './OnRobot2FG7/implementation/OnRobot2FG7';
import { OnRobot2FG7GrippersConfiguration } from './OnRobot2FG7/types/Configuration';
import { OnRobot3FG15Command } from './OnRobot3FG15';
import type { OnRobot3FG15 } from './OnRobot3FG15/implementation/OnRobot3FG15';
import { OnRobot3FG15GrippersConfiguration } from './OnRobot3FG15/types/Configuration';
import { OnRobotDualQuickChangerCommand } from './OnRobotDualQuickChanger';
import type { OnRobotDualQuickChanger } from './OnRobotDualQuickChanger/implementation/OnRobotDualQuickChanger';
import { OnRobotDualQuickChangerConfiguration } from './OnRobotDualQuickChanger/types/Configuration';
import { OnRobotScrewdriverCommand } from './OnRobotScrewdriver';
import type { OnRobotScrewdriver } from './OnRobotScrewdriver/implementation/OnRobotScrewdriver';
import { OnRobotScrewdriverConfiguration } from './OnRobotScrewdriver/types/Configuration';
import { OnRobotVGP20Command } from './OnRobotVGP20';
import type { OnRobotVGP20 } from './OnRobotVGP20/implementation/OnRobotVGP20';
import { OnRobotVGP20Configuration } from './OnRobotVGP20/types/Configuration';
import { WristCameraConfiguration } from './WristCamera/types/Configuration';

export const DeviceCommand = zod.discriminatedUnion('kind', [
  OnRobot3FG15Command,
  OnRobot2FG7Command,
  OnRobotDualQuickChangerCommand,
  OnRobotScrewdriverCommand,
  OnRobotVGP20Command,
  EwellixLiftTLTCommand,
  ModbusTCPServerCommand,
]);

export type DeviceCommand = zod.infer<typeof DeviceCommand>;

export const DeviceConfiguration = zod.discriminatedUnion('kind', [
  OnRobotDualQuickChangerConfiguration,
  OnRobotScrewdriverConfiguration,
  OnRobotVGP20Configuration,
  OnRobot2FG7GrippersConfiguration,
  OnRobot3FG15GrippersConfiguration,
  CustomGripperConfiguration,
  EwellixLiftTLTConfiguration,
  NoGripperConfiguration,
  CustomIOConfiguration,
  InvalidConfiguration,
  ModbusTCPServerConfiguration,
  WristCameraConfiguration,
]);

export type DeviceConfiguration = zod.infer<typeof DeviceConfiguration>;

// typecheck: this ensures all Configuration types have a non-optional `name` property.
// ts will error if `name` is optional or missing:
// "Type 'string | undefined' is not assignable to type 'string'. ts(2322)"
((name: DeviceConfiguration['name']): string => name)('');

// only one right now but may become a union in the future
export const DynamicBaseState = EwellixLiftTLTState;
export type DynamicBaseState = zod.infer<typeof DynamicBaseState>;

export const DeviceState = zod.union([GripperStateNonNull, DynamicBaseState]);
export type DeviceState = zod.infer<typeof DeviceState>;

export type DeviceKind = DeviceConfiguration['kind'];

export type AnyDevice =
  | EwellixLiftTLT
  | OnRobot2FG7
  | OnRobot3FG15
  | OnRobotDualQuickChanger
  | OnRobotScrewdriver
  | OnRobotVGP20
  | CustomGripper
  | ModbusTCPServer;

export type NetworkDeviceRequest = ModbusTCPServer;
export type NetworkRequestCommand = ModbusTCPServerCommand;
