import cx from 'classnames';

export type SimpleModalContentWidth = 320 | 'auto';

const MODAL_WIDTH_CLASSES: Record<SimpleModalContentWidth, string> = {
  320: 'tw-w-320',
  auto: '',
};

export interface SimpleModalContentProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
  width?: SimpleModalContentWidth;
}

export function SimpleModalContent({
  title,
  children,
  buttons,
  width = 320,
}: SimpleModalContentProps) {
  return (
    <div
      className={cx(
        'tw-p-16',
        'tw-flex',
        'tw-flex-col',
        'tw-text-15',
        'tw-text-center',
        /* increase the width of the dialog of the buttons need more space */
        'tw-min-w-min',
        MODAL_WIDTH_CLASSES[width],
      )}
    >
      {title && (
        <header
          className={cx(
            'tw-text-17',
            'tw-font-medium',
            'tw-text-ellipsis',
            'tw-mb-2',
          )}
        >
          {title}
        </header>
      )}
      {children}
      {buttons && (
        <div
          className={cx(
            'tw-grid',
            'tw-grid-flow-col',
            'tw-auto-cols-fr',
            'tw-gap-12',
            'tw-mt-20',
            /* avoid squeezing buttons horizontally */
            'tw-min-w-max',
          )}
        >
          {buttons}
        </div>
      )}
    </div>
  );
}
