import cx from 'classnames';

import { Icon } from '../../icons';

import type { SettingsGroupItemProps } from './SettingsGroupItem';
import { SettingsGroupItem } from './SettingsGroupItem';

export interface SettingsGroupNavigationItemProps
  extends SettingsGroupItemProps {
  label?: React.ReactNode;
  disabled?: boolean;
}

export function SettingsGroupNavigationItem({
  label,
  children,
  className,
  onClick,
  disabled,
  ...rest
}: SettingsGroupNavigationItemProps) {
  return (
    <SettingsGroupItem
      role="button"
      aria-disabled={disabled}
      onClick={disabled ? undefined : onClick}
      className={cx(
        className,
        'aria-disabled:tw-text-label-quaternary',
        'aria-disabled:tw-cursor-not-allowed',
      )}
      {...rest}
    >
      <div
        className={cx('tw-flex-1', 'tw-flex', 'tw-items-center', 'tw-gap-8')}
      >
        {label}
      </div>

      {children}

      <Icon
        kind="chevronRight"
        className={cx('tw-icon-22', 'tw-text-label-quaternary', 'tw-mx-[-4px]')}
      />
    </SettingsGroupItem>
  );
}
