import * as zod from 'zod';

import { ArmJointTorques, MotionKind } from '@sb/motion-planning';
import { BlendConfig } from '@sb/motion-planning/Waypoint';

import { SpeedProfile } from '../../speed-profile';
import { ArmPosition, Expression } from '../../types';

export default zod.object({
  pushingCollisionThresholds: ArmJointTorques.optional(),
  motionKind: MotionKind,
  shouldMatchJointAngles: zod.boolean(),
  reduceSmoothing: zod.boolean().optional(),
  tcpOffsetOption: zod.number().optional(),
  target: zod.union([
    ArmPosition,
    zod.object({
      positionListID: zod.string(),
      positionListIndex: zod.number().int().nonnegative().optional(),
    }),
    zod.object({
      expression: Expression,
    }),
  ]),
  speedProfile: SpeedProfile,
  isWaypoint: zod.boolean().optional(),
  isCacheable: zod.boolean().optional(),
  blend: zod.optional(BlendConfig),
});
