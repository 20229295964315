import type { Routine } from '@sb/remote-control/types';

export function isLoadedRoutinePlayable(
  routine: Routine.ConvertedResponse,
): boolean {
  if (!routine.isActionRequired || !routine.actionRequiredByStepID) {
    return true;
  }

  if (
    Object.values(routine.actionRequiredByStepID).some(
      (actionRequired) => actionRequired.kind !== 'missingConfiguration',
    )
  ) {
    return false;
  }

  return true;
}
