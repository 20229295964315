import { MathUtils, Matrix4 } from 'three';

import {
  cartesianPoseToMatrix4,
  type CartesianPose,
  matrix4ToCartesianPose,
} from './CartesianPose';
import { EulerAngleToQuaternion } from './Quaternion';

/*
 * To vizualize the transform, imagine you are the tooltip.
 * Open the UI, look at the tooltip from "behind", and set "Align with tool"
 * to see the tooltip's axes. The tooltip's Z axis should face away from you.
 *
 * The camera position relative to the tooltip is:
 * "Back" 0.05 units "into" the tooltip (z component is -0.05)
 * "Down" 0.055 units (y component is -0.055)
 * "To the right" 0.035 units (x component is -0.035)
 * Rotated "to the left" 30 degrees (about the tooltip's X axis)
 *
 */
export const CameraRelativeToFlangeInTooltipCoordinates: CartesianPose = {
  x: -0.035,
  y: -0.055,
  z: -0.05,
  ...EulerAngleToQuaternion({ x: MathUtils.degToRad(30), y: 0, z: 0 }),
};

/**
 * Generates a transformation matrix that converts coordinates from the tooltip's coordinate system to the base coordinate system.
 *
 * Specifically, this matrix performs the following operations:
 * - The Z component in the tooltip coordinate system becomes the X component in the base coordinate system.
 * - The X component in the tooltip coordinate system becomes the Y component in the base coordinate system.
 * - The Y component in the tooltip coordinate system becomes the Z component in the base coordinate system.
 *
 * @returns {Matrix4} The transformation matrix for converting coordinates from the tooltip's coordinate system to the base coordinate system.
 */
export function tooltipToBaseTransform(): Matrix4 {
  return new Matrix4().set(
    // Z component in tooltip becomes X component in base
    0,
    0,
    1,
    0,
    // X component in tooltip becomes Y component in base
    1,
    0,
    0,
    0,
    // Y component in tooltip becomes Z component in base
    0,
    1,
    0,
    0,
    // Row for homogeneous coordinates
    0,
    0,
    0,
    1,
  );
}

/**
 * Transforms coordinates from a tooltip-based coordinate system to a base-based coordinate system.
 *
 * @param {CartesianPose} poseRelativeToWristInTooltipCoords - The pose relative to the tooltip in the tooltip's own coordinate system.
 * @param {CartesianPose} wristPose - The current pose of the tooltip, representing its position and orientation in a known coordinate system.
 *
 * @returns {CartesianPose} The transformed pose in the base coordinate system.
 */
export function getPoseFromRelativeWristPose(
  wristPose: CartesianPose,
  poseRelativeToWristInTooltipCoords: CartesianPose,
): CartesianPose {
  const wristPoseMat = cartesianPoseToMatrix4(wristPose);
  const coordinateTransformMat = tooltipToBaseTransform();
  const offsetMat = cartesianPoseToMatrix4(poseRelativeToWristInTooltipCoords);

  const cameraPose = wristPoseMat
    .multiply(coordinateTransformMat)
    .multiply(offsetMat);

  return matrix4ToCartesianPose(cameraPose);
}

export function cameraPoseFromWristPose(
  wristPose: CartesianPose,
): CartesianPose {
  return getPoseFromRelativeWristPose(
    wristPose,
    CameraRelativeToFlangeInTooltipCoordinates,
  );
}
