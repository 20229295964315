import { LoopStep } from '@sb/remote-control/types';
import type { LoopStepArguments } from '@sb/routine-runner';

import { buildConditional } from '../steps';

export const convertLoopConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: LoopStep.Arguments,
): LoopStepArguments => {
  if (!configuration) {
    throw new Error(`Loop step not configured. stepID: ${stepID}`);
  }

  if (LoopStep.isConditionArguments(configuration)) {
    return {
      condition: buildConditional(JSON.parse(configuration.condition)),
    };
  }

  if (LoopStep.isFixedTimesArguments(configuration)) {
    return {
      times: configuration.times,
    };
  }

  // Loop forever
  return {};
};
