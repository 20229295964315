import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { ArmJointPositions } from '@sb/motion-planning';

export namespace Space {
  export const Position = zod.object({
    name: zod.string().optional(),
    jointAngles: ArmJointPositions,
    pose: CartesianPose,
  });

  export type Position = zod.infer<typeof Position>;

  const ItemBase = zod.object({
    description: zod.string(),
    id: zod.string(),
    kind: zod.string(),
    name: zod.string(),
    positions: zod.array(Position),
  });

  export const FreeformPositionList = ItemBase.extend({
    kind: zod.literal('freeformPositionList'),
  });

  export type FreeformPositionList = zod.infer<typeof FreeformPositionList>;

  export const SinglePosition = ItemBase.extend({
    kind: zod.literal('singlePosition'),
  });

  export type SinglePosition = zod.infer<typeof SinglePosition>;

  export const GridPositionList = ItemBase.extend({
    cornerA: Position.nullable(),
    cornerB: Position.nullable(),
    cornerC: Position.nullable(),
    cornerD: Position.nullable(),
    kind: zod.literal('gridPositionList'),
    numColumns: zod.number(),
    numRows: zod.number(),
  });

  export type GridPositionList = zod.infer<typeof GridPositionList>;

  export const VisionPositionList = ItemBase.extend({
    kind: zod.literal('visionPositionList'),
    planeID: zod.string().optional(),
  });

  export type VisionPositionList = zod.infer<typeof VisionPositionList>;

  export const Plane = ItemBase.extend({
    kind: zod.literal('plane'),
    capturedWithCameraKind: zod.string().optional(),
  });

  export type Plane = zod.infer<typeof Plane>;

  export const EnvironmentObject = ItemBase.extend({
    kind: zod.literal('environmentObject'),
    fileURL: zod.string().nullable(),
    fileName: zod.string().nullable(),
    pose: CartesianPose,
    scale: zod.number().optional(),
  });

  export type EnvironmentObject = zod.infer<typeof EnvironmentObject>;

  export const Item = zod.discriminatedUnion('kind', [
    FreeformPositionList,
    GridPositionList,
    SinglePosition,
    VisionPositionList,
    Plane,
    EnvironmentObject,
  ]);

  export type Item = zod.infer<typeof Item>;

  export type ItemKind = Item['kind'];
}
