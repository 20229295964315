import { Quaternion, Vector3, Matrix4 } from 'three';
import * as zod from 'zod';

import type { CartesianPose } from './CartesianPose';

const Vector3Schema = zod.instanceof(Vector3);

export const Plane = zod.object({
  origin: Vector3Schema,
  plusX: Vector3Schema,
  plusY: Vector3Schema,
});

export const IDENTITY_PLANE = {
  origin: new Vector3(0, 0, 0),
  plusX: new Vector3(1, 0, 0),
  plusY: new Vector3(0, 1, 0),
};

export type Plane = zod.infer<typeof Plane>;

/**
 * Calculates the basis vectors of a plane defined by its origin, plusX, and plusY points.
 *
 * @param {Plane} plane - The plane containing origin, plusX and plusY vectors.
 * @returns {Object} - The basis vectors { x: Vector3, y: Vector3, z: Vector3 }
 */
export function getPlaneBasisVectors(plane: Plane): {
  x: Vector3;
  y: Vector3;
  z: Vector3;
} {
  const planeX = new Vector3()
    .subVectors(plane.plusX, plane.origin)
    .normalize();

  const planeY = new Vector3()
    .subVectors(plane.plusY, plane.origin)
    .normalize();

  const planeZ = new Vector3().crossVectors(planeX, planeY).normalize();

  return {
    x: planeX,
    y: planeY,
    z: planeZ,
  };
}

export function getPlaneNormal(plane: Plane): Vector3 {
  const { z } = getPlaneBasisVectors(plane);

  return z;
}

export function getPlaneOrientation(plane: Plane): Quaternion {
  const { x: planeX, y: planeY, z: planeZ } = getPlaneBasisVectors(plane);

  // Create quaternion to transform from world to plane coordinates
  const planeOrientation = new Quaternion().setFromRotationMatrix(
    new Matrix4().makeBasis(planeX, planeY, planeZ),
  );

  return planeOrientation.normalize();
}

export function getPlanePose(plane: Plane): CartesianPose {
  const planeOrientation = getPlaneOrientation(plane);
  const { origin } = plane;

  return {
    x: origin.x,
    y: origin.y,
    z: origin.z,
    i: planeOrientation.x,
    j: planeOrientation.y,
    k: planeOrientation.z,
    w: planeOrientation.w,
  };
}

export function constructPlaneFromPose(planePose: CartesianPose): Plane {
  const { i, j, k, w } = planePose;
  const planeOrientation = new Quaternion(i, j, k, w).normalize();

  const matrix = new Matrix4().makeRotationFromQuaternion(planeOrientation);

  // Extract basis vectors
  const planeX = new Vector3();
  const planeY = new Vector3();
  const planeZ = new Vector3();

  matrix.extractBasis(planeX, planeY, planeZ);

  const { x, y, z } = planePose;
  const origin = new Vector3(x, y, z);

  return {
    origin,
    plusX: planeX.add(origin),
    plusY: planeY.add(origin),
  };
}

/**
 * Get the orientation as a quaternion based on the local rotation on a plane.
 *
 * @param {number} rotation - The local rotation in radians.
 * @param {Plane} plane - The plane containing origin, plusX and plusY vectors.
 * @returns {Quaternion} - The resulting orientation as a quaternion.
 */
export function getOrientationFromLocalRotationOnPlane(
  rotation: number,
  plane: Plane,
): Quaternion {
  const orientation = getPlaneOrientation(plane);

  const localRotation = new Quaternion().setFromAxisAngle(
    // The Z component of the plane is normal to the plane
    new Vector3(0, 0, 1),
    rotation,
  );

  const finalOrientation = orientation.multiply(localRotation);

  return finalOrientation;
}
