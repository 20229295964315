import type { Step } from '@sb/remote-control/types';

/** Is LoopControl step that isn't inside a loop. */
export function isInvalidLoopControlStep(step: Step.ConvertedSummary): boolean {
  // Only check `LoopControl` steps.
  if (step.stepKind !== 'LoopControl') return false;

  // Check if this step is a child of a 'Loop' step.
  const isParentALoopStep = step.parentSteps.some(
    (parentStep) => parentStep?.stepKind === 'Loop',
  );

  // If we can't find any 'Loop' steps in parent nodes, then
  // this step is invalid.
  return !isParentALoopStep;
}
