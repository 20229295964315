import type { OnRobot3FG15Command } from '../Command';
import type { OR3FG15FingerPosition } from '../constants';
import {
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_OFFSETS,
  OR_3FG15_TO_FINGER_OFFSET,
} from '../constants';

/**
 * Calculates the diameter in meters of an OR3FG15 when in the state
 * described by the parameters.
 */
export function calculateOR3FG15DiameterFromFingerAngle({
  fingerPosition,
  fingerLength = OR_3FG15_DEFAULT_FINGER_LENGTH,
  fingertipDiameter,
  angle,
  gripKind,
}: {
  fingerPosition: OR3FG15FingerPosition;
  fingerLength?: number;
  fingertipDiameter: number;
  // the angle of the fingers
  angle: number;
  gripKind: OnRobot3FG15Command['gripKind'];
}): number {
  // determine offset from finger length and position
  const offset =
    fingerLength -
    OR_3FG15_DEFAULT_FINGER_LENGTH +
    OR_3FG15_OFFSETS[fingerPosition];

  // Law of Cosines for SAS triangle
  const cosAngle = Math.cos(Math.PI - angle);

  const rawDiameter =
    2 *
    Math.sqrt(
      offset ** 2 +
        OR_3FG15_TO_FINGER_OFFSET ** 2 -
        2 * offset * OR_3FG15_TO_FINGER_OFFSET * cosAngle,
    );

  if (gripKind === 'inward') {
    // Gripper hardware uses 1/10th of a mm for diameter, so round to that
    return Math.round((rawDiameter - fingertipDiameter) * 10_000) / 10_000;
  }

  // Gripper hardware uses 1/10th of a mm for diameter, so round to that
  return Math.round((rawDiameter + fingertipDiameter) * 10_000) / 10_000;
}
