import type { OnRobot2FG7Command } from './Command';
import {
  FORCE_RANGE,
  OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS,
  OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS,
} from './constants';
import type { OnRobot2FG7State } from './State';

export function calculateOR2FG7StateFromCommand(
  command: OnRobot2FG7Command,
): Partial<OnRobot2FG7State> {
  return {
    kind: 'OnRobot2FG7',
    isBusy: false,
    isConnected: true,
    isGripDetected: false,
    gripKind: command.gripKind,
    force: command.targetForce ?? 0,
    width: {
      inner: command.targetDiameter,
      outer: command.targetDiameter,
    },
    maxForce: FORCE_RANGE.max,
    maxWidth: {
      inner: OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS.max,
      outer: OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS.max,
    },
    minWidth: {
      inner: OUTWARD_MOUNT_INWARD_GRIP_WIDTH_RANGE_METERS.min,
      outer: OUTWARD_MOUNT_OUTWARD_GRIP_WIDTH_RANGE_METERS.min,
    },
    linearSensorError: false,
    uncalibratedError: false,
  };
}
