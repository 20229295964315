import { forwardKinematics } from '@sb/motion-planning';
import type { Step } from '@sb/remote-control/types';
import { DEFAULT_REGION_OF_INTEREST } from '@sb/routine-runner';

/**
 * Where the shape of the step configuration changes,
 * this function migrates the configuration data to the new shape
 */
export function migrateStepConfiguration(
  stepConfiguration: Step.ConvertedConfiguration,
): void {
  if (!stepConfiguration.args) {
    return;
  }

  const args = stepConfiguration.args as any;

  switch (stepConfiguration.stepKind) {
    case 'MoveArmTo': {
      /** motionKind moved to top level */
      if (args.target) {
        if (args.target.motionKind) {
          if (!args.motionKind) {
            args.motionKind = args.target.motionKind;
          }

          args.target.motionKind = undefined;
        }

        if (!args.target.jointAngles) {
          args.target = null;
        } else if (!args.target.pose) {
          args.target.pose = forwardKinematics(args.target.jointAngles);
        }
      }

      if (args.shouldMatchJointAngles === undefined) {
        args.shouldMatchJointAngles = args.motionKind === 'joint';
      }

      break;
    }

    case 'ActuateVacuum': {
      if (args.overallSuctionPercentage === undefined) {
        args.overallSuctionPercentage = Math.max(
          args.oneSuctionPercentage ?? 0,
          args.twoSuctionPercentage ?? 0,
          args.threeSuctionPercentage ?? 0,
          args.fourSuctionPercentage ?? 0,
        );

        args.oneSuctionPercentage = undefined;
        args.twoSuctionPercentage = undefined;
        args.threeSuctionPercentage = undefined;
        args.fourSuctionPercentage = undefined;
      }

      break;
    }

    case 'AddOffset': {
      if (args.distanceUnit === undefined) {
        args.distanceUnit = 'meter';
      }

      break;
    }

    case 'Classify': {
      /* region of interest made non-nullable */
      if (args.regionOfInterest === null) {
        args.regionOfInterest = DEFAULT_REGION_OF_INTEREST;
      }

      break;
    }

    case 'Locate': {
      /* Added Circularity params */
      if (args.method.kind === 'BlobDetection2D') {
        if (args.method.settings.filterByCircularity === undefined) {
          args.method.settings.filterByCircularity = false;
        }

        if (args.method.settings.minCircularity === undefined) {
          args.method.settings.minCircularity = 0.1;
        }

        if (args.method.settings.maxCircularity === undefined) {
          args.method.settings.maxCircularity = 1.0;
        }
      }

      break;
    }

    default:
      break;
  }
}
