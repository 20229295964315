import '@sb/design-system';
/* remove the next import when everything is moved over to the new design system */
import '@sb/ui/global-tailwind.css';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useMemo } from 'react';

import AppProvider from '@sbrc/components/AppProvider';
import { getPageTitle } from '@sbrc/utils';

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const pageTitle = useMemo(() => getPageTitle(), []);

  /**
   * This effect prevents the context menu from showing up. This is an issue
   * especially for touch screens where the context menu would be displayed
   * when we would hold a button for a longer period of time. Since the
   * "hold and release" behavior occurs very often in our UI, we've decided
   * to disable the context menu everywhere.
   */
  useEffect(() => {
    const eventHandler = (event: MouseEvent) => {
      if (event instanceof PointerEvent && event.pointerType === 'mouse')
        return;
      event.preventDefault();
    };

    window.addEventListener('contextmenu', eventHandler);

    return () => window.removeEventListener('contextmenu', eventHandler);
  }, []);

  return (
    <AppProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#eeeff1" />
        <title>{pageTitle}</title>
      </Head>
      <Component {...pageProps} />
    </AppProvider>
  );
};

export default CustomApp;
