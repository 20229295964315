import type { ZodType } from 'zod';

import type {
  DeviceKinematics,
  MotionPlannerInterface,
} from '@sb/motion-planning';
import { EventEmitter } from '@sb/utilities';
import type { EventData } from '@sb/utilities';

import type { DeviceCommand, DeviceConfiguration, DeviceKind } from './device';

type DeviceEvents<ReasonKind = string> = {
  destroy: ReasonKind;
};

/**
 * A Device is the botman-side implementation of equipment that we support.
 * It can be found in the `implementation` directory in each integration.
 */
export abstract class Device<
  State extends object,
  Events = {},
> extends EventEmitter<Events & DeviceEvents> {
  public abstract stateSchema: ZodType<State>;

  // cannot use in the constructor
  protected motionPlanner!: MotionPlannerInterface;

  public setMotionPlanner(motionPlanner: MotionPlannerInterface) {
    this.motionPlanner = motionPlanner;
  }

  public abstract kinematics: DeviceKinematics<DeviceCommand>;

  public abstract kind: DeviceKind;

  public abstract actuate(command: DeviceCommand): Promise<void>;

  public abstract initialize(): Promise<void> | void;

  public destroy(
    ...reason: EventData<(Events & DeviceEvents)['destroy']>
  ): void {
    this.emit('destroy', ...reason);
  }

  public abstract stop(): Promise<void> | void | undefined;

  public abstract update(): State;

  public abstract updateConfig(
    newConfig: DeviceConfiguration,
  ): Promise<void> | void;

  public untilDestroyed() {
    return this.next('destroy');
  }

  public checkDeviceConnectedAtInitialization(): Promise<void> | void {}

  public abstract getConfig(): DeviceConfiguration | void;
}
