import type { Routine, Step } from '@sb/remote-control/types';

import { DECORATOR_STEP_LIST, findStep } from '../steps';

export function isInvalidWaypointStep(
  _step: unknown,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
  { steps }: Pick<Routine.ConvertedResponse, 'steps'>,
): boolean {
  if (
    !stepConfiguration ||
    stepConfiguration.stepKind !== 'MoveArmTo' ||
    !stepConfiguration.args?.isWaypoint
  ) {
    return false;
  }

  let isWaypointFound = false;

  // find first step after waypoint which isn't a decorator step
  const firstNonDecoratorStepAfterWaypoint = findStep(steps, (step) => {
    if (step.id === stepConfiguration.id) {
      isWaypointFound = true;

      return false;
    }

    return isWaypointFound && !DECORATOR_STEP_LIST.includes(step.stepKind);
  });

  return (
    !firstNonDecoratorStepAfterWaypoint ||
    firstNonDecoratorStepAfterWaypoint.stepKind !== 'MoveArmTo'
  );
}
