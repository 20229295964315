import cx from 'classnames';
import { forwardRef } from 'react';

import * as ICONS from './icon-library';

export type IconKind = keyof typeof ICONS;

export type IconSize = 42 | 28 | 22 | 20 | 18;

export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  kind: IconKind;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ kind, className, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        role={rest.onClick ? 'button' : undefined}
        className={cx(
          className,
          'aria-disabled:tw-opacity-40',
          'aria-disabled:tw-cursor-not-allowed',
          'aria-pressed:tw-opacity-40',
        )}
        {...rest}
        viewBox="0 0 28 28"
        data-icon-kind={kind}
      >
        {ICONS[kind]}
      </svg>
    );
  },
);
