import * as zod from 'zod';

import { getIntegrationTypes } from '@sb/integrations/register';

import AddOffsetStepArguments from './AddOffset/Arguments';
import AnticipatePayloadStepArguments from './AnticipatePayload/Arguments';
import ClassifyStepArguments from './Classify/Arguments';
import IfStepArguments from './If/Arguments';
import LocateStepArguments from './Locate/Arguments';
import LoopStepArguments from './Loop/Arguments';
import LoopControlStepArguments from './LoopControl/Arguments';
import MoveArmToStepArguments from './MoveArmTo/Arguments';
import { NetworkRequestArguments } from './NetworkRequest/Arguments';
import PressButtonStepArguments from './PressButton/Arguments';
import RunInBackgroundStepArguments from './RunInBackground/Arguments';
import SetEnvironmentVariableArguments from './SetEnvironmentVariable/Arguments';
import SetIOStepArguments from './SetIO/Arguments';
import WaitStepArguments from './Wait/Arguments';
import WaitForConfirmationStepArguments from './WaitForConfirmation/Arguments';

// Shared values for all step configurations.
// Includes steps, a recursive type. Zod cannot perfectly infer recursive types,
// so we need to include type hints for this part of the configuration.
export type BaseRoutineStepConfiguration = {
  id: string;
  description?: string;
  // eslint-disable-next-line no-use-before-define
  steps?: [RoutineStepConfiguration, ...RoutineStepConfiguration[]];
};

export const BaseRoutineStepConfiguration: zod.ZodSchema<BaseRoutineStepConfiguration> =
  zod.lazy(() =>
    zod.object({
      id: zod.string(),
      description: zod.string().optional(),
      // eslint-disable-next-line no-use-before-define
      steps: zod.array(RoutineStepConfiguration).nonempty().optional(),
    }),
  );

export const TaggedStepConfigUnion = zod.discriminatedUnion('stepKind', [
  zod.object({
    stepKind: zod.literal('AnticipatePayload'),
    args: AnticipatePayloadStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('AddOffset'),
    args: AddOffsetStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('Classify'),
    args: ClassifyStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('Locate'),
    args: LocateStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('If'),
    args: IfStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('Loop'),
    args: LoopStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('LoopControl'),
    args: LoopControlStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('MoveArmTo'),
    args: MoveArmToStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('NetworkRequest'),
    args: NetworkRequestArguments,
  }),
  zod.object({
    stepKind: zod.literal('PressButton'),
    args: PressButtonStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('RunInBackground'),
    args: RunInBackgroundStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('SetEnvironmentVariable'),
    args: SetEnvironmentVariableArguments,
  }),
  zod.object({
    stepKind: zod.literal('SetIO'),
    args: SetIOStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('WaitForConfirmation'),
    args: WaitForConfirmationStepArguments,
  }),
  zod.object({
    stepKind: zod.literal('Wait'),
    args: WaitStepArguments,
  }),
  ...getIntegrationTypes().map(({ stepKind, Arguments }) =>
    zod.object({
      stepKind: zod.literal(stepKind),
      args: Arguments,
    }),
  ),
]);

type TaggedStepConfigUnion = zod.infer<typeof TaggedStepConfigUnion>;

export type RoutineStepConfiguration = TaggedStepConfigUnion &
  BaseRoutineStepConfiguration;

export const RoutineStepConfiguration: zod.ZodSchema<RoutineStepConfiguration> =
  zod.lazy(() => BaseRoutineStepConfiguration.and(TaggedStepConfigUnion));
