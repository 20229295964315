import type { RoutineData } from '@sb/feathers-types';
import type { Routine, User } from '@sb/remote-control/types';

import { validateRoutine } from './validateRoutine';

export async function createRoutineDocument(
  routine: Partial<Routine.EditableFields>,
  createdBy: User.ConvertedResponse,
): Promise<Partial<RoutineData>> {
  const newRoutine = {
    ...routine,
    createdByID: createdBy.id,
    createdByName: createdBy.name,
    createdByPhoto: createdBy.photo,
  };

  await validateRoutine(newRoutine);

  return newRoutine;
}
