import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { CameraIntegration } from '@sb/integrations/camera/types';

import {
  Blob2DParams,
  RegionOfInterest,
  Shape2DParams,
  TemplateImage,
} from '../../VisionInterface';

const BlobDetection2DMethod = zod.object({
  kind: zod.literal('BlobDetection2D'),
  settings: Blob2DParams,
});

const ShapeDetection2DMethod = zod.object({
  kind: zod.literal('ShapeDetection2D'),
  templateImage: TemplateImage,
  settings: Shape2DParams,
});

const LocateMethod = zod.union([BlobDetection2DMethod, ShapeDetection2DMethod]);

export default zod.object({
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  method: LocateMethod,
  positionListID: zod.string(), // The position list to output results to
  filters: zod.object({
    resultsLimit: zod.optional(zod.number()), // The max number of results to output
  }),
  transform: CartesianPose,
  planeID: zod.string(),
});
