import cx from 'classnames';
import { forwardRef, useState } from 'react';

import { Icon } from '../../../icons';
import type { ComponentSize } from '../../../utility';
import { Popper } from '../../../utility';
import { INPUT_SIZE_DEFAULT, INPUT_SIZE_CLASSES } from '../inputSize';
import type { InputVariant } from '../inputVariant';
import { INPUT_VARIANT_CLASSES, INPUT_VARIANT_DEFAULT } from '../inputVariant';

export interface RichSelectProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  options: React.ReactNode;
  size?: ComponentSize;
  variant?: InputVariant;
}

export const RichSelect = forwardRef<HTMLButtonElement, RichSelectProps>(
  (
    {
      className,
      onClick,
      children,
      options,
      size = INPUT_SIZE_DEFAULT,
      variant = INPUT_VARIANT_DEFAULT,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Popper
        placement="bottom-start"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={options}
        className={cx('tw-inline-flex', 'tw-flex-col')}
      >
        <button
          {...rest}
          ref={ref}
          className={cx(
            className,
            'tw-inline-flex',
            INPUT_SIZE_CLASSES[size],
            INPUT_VARIANT_CLASSES[variant],
            variant === 'Flat' && 'tw-px-0',
            'tw-gap-4',
            'tw-text-label-primary',
            'tw-overflow-hidden',
            'disabled:tw-bg-fill-primary',
            'disabled:tw-text-label-quaternary',
            'disabled:tw-shadow-none',
            'tw-select-none',
            'tw-text-left',
            'tw-items-center',
          )}
          onClick={(e) => {
            setIsOpen(!isOpen);
            onClick?.(e);
          }}
          type="button"
        >
          <div className="tw-flex-1 tw-text-ellipsis">{children}</div>
          <Icon
            kind="chevronUpDown"
            className={cx(
              'tw-text-label-secondary',
              'tw-flex-none',
              '[:disabled>&]:tw-text-label-quaternary',
            )}
          />
        </button>
      </Popper>
    );
  },
);
