import { mapPaginated } from '@sb/feathers-types';
import type { Routine } from '@sb/remote-control/types';

import { getAllEnabledEquipment } from '../equipment/getAllEquipment';

import { convertRoutineResponse, getRoutinesService } from './utils';

export async function getRoutine(
  routineID: string,
): Promise<Routine.ConvertedResponse | null> {
  const [response, equipment] = await Promise.all([
    getRoutinesService().find({
      query: { id: routineID, $limit: 1 },
    }),
    getAllEnabledEquipment(),
  ]);

  const routine = mapPaginated(response, convertRoutineResponse)[0] ?? null;

  return {
    ...routine,
    equipment: equipment.map((e) => e.config),
  };
}
