import type { Step } from '@sb/remote-control/types';

import type { HaasRunProgramArguments } from '../steps/HaasRunProgram/Arguments';

/**
 * Types matching the routine runner schema for "HaasRunProgram" Steps
 */
export namespace HaasRunProgramStepDatabase {
  /** Arguments to be passed to the routine runner */
  export interface Arguments
    extends Step.CommonArguments,
      HaasRunProgramArguments {
    argumentKind: 'HaasRunProgram';
  }

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): HaasRunProgramArguments => {
    if (!configuration) {
      throw new Error(
        `HaasRunProgram step is not configured. stepID: ${stepID}`,
      );
    }

    return configuration;
  };
}
