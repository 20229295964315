import * as zod from 'zod';

import type { CartesianPose } from '@sb/geometry';
import type { CameraIntrinsics } from '@sb/integrations/camera/types';

export const ClassifyClass = zod.object({
  name: zod.string(),
  storageID: zod.string(),
});

export type ClassifyClass = zod.infer<typeof ClassifyClass>;

export const ClassifyResult = zod.object({
  name: zod.string(),
  score: zod.number(),
});

export type ClassifyResult = zod.infer<typeof ClassifyResult>;

export const RegionOfInterest = zod.object({
  top: zod.number(),
  bottom: zod.number(),
  left: zod.number(),
  right: zod.number(),
});

export type RegionOfInterest = zod.infer<typeof RegionOfInterest>;

export const Blob2D = zod.object({
  x: zod.number(),
  y: zod.number(),
  width: zod.number(),
  height: zod.number(),
  rotation: zod.number(), // radians
  score: zod.number(),
  contour: zod
    .array(
      zod.object({
        x: zod.number(),
        y: zod.number(),
      }),
    )
    .default([]),
});

export type Blob2D = zod.infer<typeof Blob2D>;

export const Blob2DParams = zod.object({
  minArea: zod.number(),
  maxArea: zod.number(),
  minThreshold: zod.number(),
  maxThreshold: zod.number(),
  minCircularity: zod.number(),
  maxCircularity: zod.number(),
  filterByCircularity: zod.boolean(),
});

export const Shape2DParams = zod.object({
  minScale: zod.number(),
  maxScale: zod.number(),
  maxRotation: zod.number(),
  matchThreshold: zod.number(),
});

export type Blob2DParams = zod.infer<typeof Blob2DParams>;
export type Shape2DParams = zod.infer<typeof Shape2DParams>;

export const Point2D = zod.object({ x: zod.number(), y: zod.number() });
export type Point2D = zod.infer<typeof Point2D>;

export const TemplateImage = zod.object({
  storageID: zod.string(),
});
export type TemplateImage = zod.infer<typeof TemplateImage>;

export const DEFAULT_REGION_OF_INTEREST: RegionOfInterest = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export interface VisionInterface {
  classify(
    classes: ClassifyClass[],
    currentFrame: ArrayBuffer,
    regionOfInterest: RegionOfInterest,
  ): Promise<ClassifyResult[]>;
  detect2DBlobs(
    image: ArrayBuffer,
    regionOfInterest: RegionOfInterest,
    params: Blob2DParams,
  ): Promise<Blob2D[]>;
  detect2DShapes(
    image: ArrayBuffer,
    templateImage: TemplateImage,
    regionOfInterest: RegionOfInterest,
    params: Shape2DParams,
  ): Promise<Blob2D[]>;
  getChessboardCorners(
    image: ArrayBuffer,
    rows: number,
    cols: number,
  ): Promise<Point2D[]>;
  getCameraChessboardTransform(
    image: ArrayBuffer,
    rows: number,
    cols: number,
    squareSizeMM: number,
    intrinsics: CameraIntrinsics,
  ): Promise<CartesianPose>;
}
