import { Icon } from '../../icons';
import { useInterval } from '../../utility';
import { HoldableButton } from '../button';

import { GaugeContainer } from './GaugeContainer';
import type { GaugeSliderProps } from './GaugeSlider';
import { GaugeSlider } from './GaugeSlider';
import { calculateClampedRoundedValue, INTERVAL_OPTIONS } from './util';

export interface GaugeStepperSingleValueProps extends GaugeSliderProps {
  targetValue?: undefined;
}

export function GaugeStepperSingleValue({
  min,
  max,
  value,
  isDisabled,
  onChange,
  onReset,
  className,
  ...rest
}: GaugeStepperSingleValueProps) {
  const { stopInterval, startInterval } = useInterval();

  const handleHold = (step: number) => () => {
    let currentValue = value;

    startInterval(() => {
      currentValue += step;
      onChange(calculateClampedRoundedValue(currentValue, min, max, step));
    }, INTERVAL_OPTIONS);
  };

  return (
    <GaugeContainer className={className}>
      <HoldableButton
        className="tw-rounded-6"
        onHold={handleHold(-rest.step)}
        onRelease={stopInterval}
        isPulseDisabled
        disabled={isDisabled || value <= min}
      >
        <Icon kind="minus" />
      </HoldableButton>

      <GaugeSlider
        min={min}
        max={max}
        value={value}
        isDisabled={isDisabled}
        onChange={onChange}
        {...rest}
      />

      <HoldableButton
        className="tw-rounded-6"
        onHold={handleHold(rest.step)}
        onRelease={stopInterval}
        isPulseDisabled
        disabled={isDisabled || value >= max}
      >
        <Icon kind="plus" />
      </HoldableButton>
    </GaugeContainer>
  );
}
