import { Icon } from '../../icons';

import type { ButtonProps } from './Button';
import { Button } from './Button';

export const ButtonClose = ({ ...other }: ButtonProps) => {
  return (
    <Button
      variant="Flat"
      aria-label="close"
      data-testid="close-button"
      className="tw-icon-28 disabled:tw-opacity-60"
      {...other}
    >
      <Icon
        kind="xmarkCircleTransparentFill"
        className="tw-mt-[3px] tw-text-label-primary"
      />
    </Button>
  );
};
