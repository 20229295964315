import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { COMPUTEBOX_HOST_DEFAULT } from '@sb/integrations/gripper-general';

import { ORVGP20_DEFAULT_TCP_TRANSFORM, ORVGP20_SHORTNAME } from '../constants';

export const OnRobotVGP20Configuration = zod.object({
  kind: zod.literal('OnRobotVGP20'),
  name: zod.string().default(ORVGP20_SHORTNAME),
  connectionOptions: zod
    .object({
      host: zod.string().default(COMPUTEBOX_HOST_DEFAULT),
      port: zod.number().default(502),
    })
    .default({}),
  tcpTransform: CartesianPose.default(ORVGP20_DEFAULT_TCP_TRANSFORM),
});

export type OnRobotVGP20Configuration = zod.infer<
  typeof OnRobotVGP20Configuration
>;
