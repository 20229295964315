import cx from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useComponentColorClassName, usePortalContainer } from '../../utility';

import type { ButtonProps } from './Button';
import { Button } from './Button';
import { PULSE_COLOR_CLASSES } from './buttonColor';

export interface HoldableButtonProps extends ButtonProps {
  /** Hold action. If it returns a promise then will automatically release when resolved */
  onHold: () => void | Promise<void>;
  onRelease: () => void;
  isPulseDisabled?: boolean;
}

export const HoldableButton = forwardRef<
  HTMLButtonElement,
  HoldableButtonProps
>((props, ref) => {
  const {
    disabled,
    onHold,
    onRelease,
    isPulseDisabled = false,
    ...rest
  } = props;

  const pulseContainer = usePortalContainer();

  // keeps the position of the button element while holding
  const [holding, setHolding] = useState<DOMRect | null>(null);

  const pulseColorClassName = useComponentColorClassName(
    rest.color,
    PULSE_COLOR_CLASSES,
  );

  useEffect(() => {
    // Set not-holding when button becomes disabled
    if (disabled) {
      setHolding(null);
    }
  }, [disabled]);

  useEffect(() => {
    // Occasionally in Safari an orientation change appears to stop a pointer event firing
    // Also in Safari, if you switch tab while holding the button, neither pointerup nor pointercancel
    // events are fired. Listen to document visibility change for this case.

    const cancelHolding = () => setHolding(null);

    document.addEventListener('visibilitychange', cancelHolding);
    // orientationchange is deprecated, but screen.orientation only available in Safari 16.4+
    window.addEventListener('orientationchange', cancelHolding);
    window.screen.orientation?.addEventListener('change', cancelHolding);

    return () => {
      document.removeEventListener('visibilitychange', cancelHolding);
      window.removeEventListener('orientationchange', cancelHolding);
      window.screen.orientation?.removeEventListener('change', cancelHolding);
    };
  }, []);

  useEffect(
    () => {
      // Invoke onHold when holding starts
      // Invoke onRelease when holding ends (i.e. pointer up or button becomes not-holdable)
      if (holding) {
        let isReleased = false;

        onHold?.()?.finally?.(() => {
          if (!isReleased) {
            // if still holding when the hold action is resolved, then auto release
            setHolding(null);
          }
        });

        return () => {
          isReleased = true;
          onRelease?.();
        };
      }

      return undefined;
    },
    // don't run effect (or teardown) on change of onRelease/onHold
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [holding],
  );

  const handlePointerUp = () => {
    setHolding(null);
  };

  const isPulsing = holding !== null && !isPulseDisabled;

  return (
    <>
      <Button
        ref={ref}
        disabled={disabled}
        onPointerDown={(event) => {
          if (!disabled && event.buttons === 1) {
            event.currentTarget.setPointerCapture(event.pointerId);
            setHolding(event.currentTarget.getBoundingClientRect());
          }
        }}
        onPointerUp={handlePointerUp}
        onPointerCancel={handlePointerUp}
        onPointerMove={(event) => {
          const { left, right, top, bottom } =
            event.currentTarget.getBoundingClientRect();

          // should invoke onRelease when user moves the touch a short distance away from the button
          const MARGIN = 30;

          if (
            event.clientX < left - MARGIN ||
            event.clientX > right + MARGIN ||
            event.clientY < top - MARGIN ||
            event.clientY > bottom + MARGIN
          ) {
            setHolding(null);
          }
        }}
        onLostPointerCapture={handlePointerUp}
        {...rest}
      />

      {isPulsing &&
        createPortal(
          <div
            className={cx(
              'tw-fixed',
              'tw-z-40',
              'tw-pointer-events-none',
              'tw-h-[150px]',
              'tw-mt-[-75px]',
              'tw-w-[150px]',
              'tw-ml-[-75px]',
              'tw-rounded-full',
              'tw-animate-growAndFade',
              pulseColorClassName,
            )}
            style={
              holding
                ? {
                    top: `${holding.top + holding.height / 2}px`,
                    left: `${holding.left + holding.width / 2}px`,
                  }
                : undefined
            }
          />,
          pulseContainer,
        )}
    </>
  );
});
