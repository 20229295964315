import type { Step } from '@sb/remote-control/types';

export function isInvalidOffsetMoveArmStep(
  step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): boolean {
  if (!stepConfiguration || stepConfiguration.stepKind !== 'MoveArmTo') {
    return false;
  }

  const hasAddOffsetParentStep = step.parentSteps.some(
    (parentStep) => parentStep?.stepKind === 'AddOffset',
  );

  return (
    hasAddOffsetParentStep &&
    (stepConfiguration.args?.shouldMatchJointAngles ?? false)
  );
}
