import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { OnRobotConnectionConfiguration } from '@sb/integrations/gripper-general/types/Configuration';

import {
  OR2FG7FingerOrientation,
  OR2FG7_DEFAULT_TCP_TRANSFORM,
  OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT,
  OR_2FG7_FINGER_POSITION_DEFAULT,
  OR2FG7_SHORTNAME,
} from '../constants';

// database equipment configuration
export const OnRobot2FG7FingerConfiguration = zod
  .object({
    mountingPosition: OR2FG7FingerOrientation.default(
      OR_2FG7_FINGER_POSITION_DEFAULT,
    ),
    offsetInMeters: zod
      .number()
      .default(OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT),
  })
  .default({});

export type OnRobot2FG7FingerConfiguration = zod.infer<
  typeof OnRobot2FG7FingerConfiguration
>;

export const OnRobot2FG7GrippersConfiguration = zod.object({
  kind: zod.literal('OnRobot2FG7'),
  name: zod.string().default(OR2FG7_SHORTNAME),
  fingerConfiguration: OnRobot2FG7FingerConfiguration,
  connectionOptions: OnRobotConnectionConfiguration,
  tcpTransform: CartesianPose.default(OR2FG7_DEFAULT_TCP_TRANSFORM),
});

export type OnRobot2FG7GrippersConfiguration = zod.infer<
  typeof OnRobot2FG7GrippersConfiguration
>;

export const ON_ROBOT_2FG7_GRIPPERS_CONFIGURATION_DEFAULT =
  OnRobot2FG7GrippersConfiguration.parse({ kind: 'OnRobot2FG7' });
