import * as zod from 'zod';

import { CartesianPose, CartesianPosition } from '@sb/geometry';
import {
  CameraIntegration,
  CameraIntrinsics,
} from '@sb/integrations/camera/types';
import { three } from '@sb/utilities';

import {
  Blob2D,
  Blob2DParams,
  ClassifyClass,
  ClassifyResult,
  Point2D,
  RegionOfInterest,
  Shape2DParams,
  TemplateImage,
} from '../VisionInterface';

/* classify */

const RunClassifyArgs = zod.object({
  method: zod.literal('classify'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  classes: ClassifyClass.array(),
});

const RunClassifyResult = zod.object({
  method: zod.literal('classify'),
  results: ClassifyResult.array(),
});

/* detect 2d blobs */

const RunDetect2DBlobsArgs = zod.object({
  method: zod.literal('detect2DBlobs'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  params: Blob2DParams,
});

export type RunDetect2DBlobsArgs = zod.infer<typeof RunDetect2DBlobsArgs>;

const RunDetect2DBlobsResult = zod.object({
  method: zod.literal('detect2DBlobs'),
  results: Blob2D.array(),
});

/* detect 2d shapes */

const RunDetect2DShapesArgs = zod.object({
  method: zod.literal('detect2DShapes'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  params: Shape2DParams,
  templateImage: TemplateImage,
});

export type RunDetect2DShapesArgs = zod.infer<typeof RunDetect2DShapesArgs>;

const RunDetect2DShapesResult = zod.object({
  method: zod.literal('detect2DShapes'),
  results: Blob2D.array(),
});

/* get chessboard corners */

const RunGetChessboardCornersArgs = zod.object({
  method: zod.literal('getChessboardCorners'),
  camera: CameraIntegration,
  rows: zod.number(),
  cols: zod.number(),
});

const RunGetChessboardCornersResult = zod.object({
  method: zod.literal('getChessboardCorners'),
  results: Point2D.array(),
});

/* get_camera_chessboard_transform */

const RunGetCameraChessboardTransformArgs = zod.object({
  method: zod.literal('getCameraChessboardTransform'),
  camera: CameraIntegration,
  rows: zod.number(),
  cols: zod.number(),
  squareSizeMM: zod.number(),
});

const RunGetCameraChessboardTransformResult = zod.object({
  method: zod.literal('getCameraChessboardTransform'),
  results: CartesianPose,
});

/* locate */

const RunLocateCommonArgs = zod.object({
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  plane: zod.tuple(three(CartesianPosition)),
  resultsLimit: zod.number(),
});

const RunLocate2DBlobArgs = RunLocateCommonArgs.extend({
  method: zod.literal('locate2DBlobs'),
  params: Blob2DParams,
});

export type RunLocate2DBlobArgs = zod.infer<typeof RunLocate2DBlobArgs>;

const RunLocate2DShapeArgs = RunLocateCommonArgs.extend({
  method: zod.literal('locate2DShapes'),
  params: Shape2DParams,
  templateImage: TemplateImage,
});

export type RunLocate2DShapeArgs = zod.infer<typeof RunLocate2DShapeArgs>;

const RunLocateCommonResults = zod.object({
  results: zod
    .object({
      blob: Blob2D,
      pose: CartesianPose,
    })
    .array(),
});

const RunLocate2DBlobsResult = RunLocateCommonResults.extend({
  method: zod.literal('locate2DBlobs'),
});

const RunLocate2DShapesResult = RunLocateCommonResults.extend({
  method: zod.literal('locate2DShapes'),
});

/* get intrinsics */

const GetIntrinsicsArgs = zod.object({
  method: zod.literal('getIntrinsics'),
});

const GetIntrinsicsResult = zod.object({
  method: zod.literal('getIntrinsics'),
  results: CameraIntrinsics,
});

/* union */

export const RunVisionMethodArgs = zod.discriminatedUnion('method', [
  RunClassifyArgs,
  RunDetect2DBlobsArgs,
  RunDetect2DShapesArgs,
  RunGetChessboardCornersArgs,
  RunGetCameraChessboardTransformArgs,
  RunLocate2DBlobArgs,
  RunLocate2DShapeArgs,
  GetIntrinsicsArgs,
]);

export type RunVisionMethodArgs = zod.infer<typeof RunVisionMethodArgs>;

export const RunVisionMethodResult = zod.discriminatedUnion('method', [
  RunClassifyResult,
  RunDetect2DBlobsResult,
  RunDetect2DShapesResult,
  RunGetChessboardCornersResult,
  RunGetCameraChessboardTransformResult,
  RunLocate2DBlobsResult,
  RunLocate2DShapesResult,
  GetIntrinsicsResult,
]);

export type RunVisionMethodResult = zod.infer<typeof RunVisionMethodResult>;
