import { useMemo } from 'react';

import { joints } from '@sb/utilities/src/joints';
import type { softwareUpdate } from '@sbrc/hooks';

export type JointStatusKind =
  | 'requiresUpdate'
  | 'installing'
  | 'upToDate'
  | 'failed';
type JointNumber = 0 | 1 | 2 | 3 | 10 | 5 | 4 | 6 | 12 | 13;
type JointName =
  | 'Joint 0'
  | 'Joint 1'
  | 'Joint 2'
  | 'Joint 3'
  | 'Joint 4'
  | 'Joint 5'
  | 'Power Board'
  | 'EE Board'
  | 'IO Board'
  | 'Safety Power Board';

interface JointUpdateStatus {
  jointNumber: JointNumber;
  jointName: JointName;
  status: JointStatusKind;
}

export interface FirmwareBuildData {
  jointUpdateStatus: JointUpdateStatus[];
  canUpdateJointFirmware: boolean;
  shouldIndicateFirmwareMismatch: boolean;
  isJointInstallInProgress: boolean;
}

export function useFirmwareUpdateStatus(
  buildData: softwareUpdate.SoftwareBuildData,
): FirmwareBuildData {
  const jointUpdateStatus: JointUpdateStatus[] = useMemo(() => {
    return joints.map(({ jointNumber, jointName }) => {
      const activeFirmware = buildData.activeFirmwareInfo?.find(
        (j) => j.jointNumber === jointNumber,
      );

      const requiresUpdate =
        activeFirmware?.buildInfo?.buildId !== buildData.activeBuildID;

      const installFailed = activeFirmware?.status === 'failed';

      let status: JointStatusKind = 'requiresUpdate';

      if (installFailed) {
        status = 'failed';
      }

      if (activeFirmware?.status === 'installing') {
        status = 'installing';
      } else if (!requiresUpdate && !installFailed) {
        status = 'upToDate';
      }

      return {
        jointNumber,
        jointName,
        status,
      };
    });
  }, [buildData]);

  const canUpdateJointFirmware = useMemo(() => {
    return jointUpdateStatus.some(
      ({ status }) => status === 'requiresUpdate' || status === 'failed',
    );
  }, [jointUpdateStatus]);

  const isJointInstallInProgress = jointUpdateStatus.some(
    ({ status }) => status === 'installing',
  );

  const shouldIndicateFirmwareMismatch =
    canUpdateJointFirmware && !buildData.isSoftwareInstallRequested;

  return {
    jointUpdateStatus,
    canUpdateJointFirmware,
    shouldIndicateFirmwareMismatch,
    isJointInstallInProgress,
  };
}
