import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { Routine, Unsubscribe } from '@sb/remote-control/types';

import { convertRoutineResponse, getRoutinesService } from './utils';

export function onGetRoutineTemplates(
  onSnapshot: (routines: Routine.ConvertedResponse[]) => void,
): Unsubscribe {
  const subscription = getRoutinesService()
    .watch()
    .find({
      query: {
        isTemplate: true,
        $sort: { name: 1 },
      },
    })
    .pipe(
      map((response) => {
        const routines = mapPaginated(response, convertRoutineResponse);

        return routines;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
