import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { CameraIntrinsics } from '@sb/integrations/camera/types';
import { ArmJointPositions } from '@sb/motion-planning';

export const WristCameraAccuracyCalibrationEntry = zod.object({
  jointAngles: ArmJointPositions,
  cameraChessboardTransform: CartesianPose,
  timestamp: zod.string(),
  offset: CartesianPose,
});

export type WristCameraAccuracyCalibrationEntry = zod.infer<
  typeof WristCameraAccuracyCalibrationEntry
>;

export const WristCameraConfiguration = zod.object({
  kind: zod.literal('WristCamera'),
  name: zod.string().default('Built-in Wrist Camera'),
  accuracyCalibration: WristCameraAccuracyCalibrationEntry.array().default([]),
  intrinsics: zod.nullable(CameraIntrinsics).default(null),
});

export type WristCameraConfiguration = zod.infer<
  typeof WristCameraConfiguration
>;

export const WRIST_CAMERA_CONFIGURATION_DEFAULT =
  WristCameraConfiguration.parse({ kind: 'WristCamera' });
