import { convertDegreesToRadians } from '@sb/utilities';

import type {
  OnRobot3FG15Configuration,
  OR3FG15FingerPosition,
  OR3FG15GripKind,
} from '../constants';
import {
  OR_3FG15_FINGER_ANGLE_MAX,
  OR_3FG15_POSITION_1_INWARD_GRIP_MIN_ANGLE,
  OR_3FG15_POSITION_2_INWARD_GRIP_MIN_ANGLE,
  OR_3FG15_POSITION_3_INWARD_GRIP_MIN_ANGLE,
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_MOVE_JOINT_MAX,
  OR_3FG15_MOVE_JOINT_MIN,
} from '../constants';

import { calculateOR3FG15DiameterFromFingerAngle } from './calculateOR3FG15DiameterFromFingerAngle';

/**
 * Calculate the internal/external grip ranges given the OnRobot3FG15 configuration
 */
export const calculateOR3FG15DiameterRange = ({
  fingerPosition,
  fingerLength = OR_3FG15_DEFAULT_FINGER_LENGTH,
  fingertipDiameter,
  actuationKind,
}: {
  fingerPosition: OR3FG15FingerPosition;
  fingerLength?: number;
  fingertipDiameter: number;
  actuationKind: 'move' | 'grip' | 'flexGrip';
}): {
  inward: { min: number; max: number };
  outward: { min: number; max: number };
  // "direct" here means with no offset (neither inward nor outward)
  // This is what the gripper gives back as a register.
  direct: { min: number; max: number };
} => {
  const grip = actuationKind === 'grip' || actuationKind === 'flexGrip';

  // These are the finger angles for minimum/maximum diameter
  const maxGripAngle = grip
    ? OR_3FG15_FINGER_ANGLE_MAX
    : OR_3FG15_MOVE_JOINT_MAX;

  const minOutwardGripAngle = grip
    ? convertDegreesToRadians(160)
    : OR_3FG15_MOVE_JOINT_MIN;

  /**
   *  Angle for external gripping min 165º (Pos 1), 163 º (Pos 2), 161 º (Pos 3) and max 30º (all 3 positions)
   * https://onrobot.com/sites/default/files/documents/Datasheet_3FG15_v1.4_EN.pdf
   */
  const minInwardGripAngles: Record<OR3FG15FingerPosition, number> = {
    1: grip
      ? convertDegreesToRadians(OR_3FG15_POSITION_1_INWARD_GRIP_MIN_ANGLE)
      : OR_3FG15_MOVE_JOINT_MIN,
    2: grip
      ? convertDegreesToRadians(OR_3FG15_POSITION_2_INWARD_GRIP_MIN_ANGLE)
      : OR_3FG15_MOVE_JOINT_MIN,
    3: grip
      ? convertDegreesToRadians(OR_3FG15_POSITION_3_INWARD_GRIP_MIN_ANGLE)
      : OR_3FG15_MOVE_JOINT_MIN,
  };

  // choose the min inward grip angle based on the finger position
  const minInwardGripAngle = minInwardGripAngles[fingerPosition];

  // calculate the diameters when the gripper has achieved these angles
  const minOutwardDiameter = calculateOR3FG15DiameterFromFingerAngle({
    fingerPosition,
    fingerLength,
    fingertipDiameter,
    angle: minOutwardGripAngle,
    gripKind: 'outward',
  });

  const maxOutwardDiameter = calculateOR3FG15DiameterFromFingerAngle({
    fingerPosition,
    fingerLength,
    fingertipDiameter,
    angle: maxGripAngle,
    gripKind: 'outward',
  });

  const minInwardDiameter = calculateOR3FG15DiameterFromFingerAngle({
    fingerPosition,
    fingerLength,
    fingertipDiameter,
    angle: minInwardGripAngle,
    gripKind: 'inward',
  });

  const maxInwardDiameter = calculateOR3FG15DiameterFromFingerAngle({
    fingerPosition,
    fingerLength,
    fingertipDiameter,
    angle: maxGripAngle,
    gripKind: 'inward',
  });

  return {
    inward: {
      min: minInwardDiameter,
      max: maxInwardDiameter,
    },
    outward: {
      min: minOutwardDiameter,
      max: maxOutwardDiameter,
    },
    direct: {
      min: (minInwardDiameter + minOutwardDiameter) / 2,
      max: (maxInwardDiameter + maxOutwardDiameter) / 2,
    },
  };
};

export function calculateOR3FG15DiameterRangeFromConfig(
  fingerConfig: OnRobot3FG15Configuration,
  gripKind: OR3FG15GripKind = 'inward',
  unit: 'meter' | 'mm' = 'meter',
): { min: number; max: number } {
  const range = calculateOR3FG15DiameterRange({
    ...fingerConfig,
    actuationKind: 'grip',
  })[gripKind];

  if (unit === 'meter') {
    return range;
  }

  return {
    min: range.min * 1000,
    max: range.max * 1000,
  };
}
